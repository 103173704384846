import { Spin } from "antd";
import { forwardRef, HtmlHTMLAttributes, PropsWithChildren, Ref, useCallback, useEffect, useMemo, useRef } from "react";

/**
 * Properties for the InfiniteScrollLoadingIndicator component.
 */
type InfiniteScrollLoadingIndicatorProps = {
  /**
   * The class name to apply to the container.
   */
  className?: HtmlHTMLAttributes<HTMLDivElement>["className"];
  /**
   * Indicates whether there are more items to load.
   */
  hasMore?: boolean;
  /**
   * Indicates whether the component is currently loading more items.
   */
  loading?: boolean;
  /**
   * The flag to determine if the component should be visible.
   */
  visible?: boolean;
};

/**
 * A loading indicator for the InfiniteScroll component.
 */
function InfiniteScrollLoadingIndicator({
  className = "flex w-full h-min[50px] justify-center align-middle p-4 gap-2 text-tpl-navy-light",
  hasMore,
  loading,
  visible = true
}: InfiniteScrollLoadingIndicatorProps, ref: Ref<HTMLDivElement>) {
  const content = useMemo(() => {
    if (loading) {
      return <>
          Loading <Spin size="small" spinning />
        </>;
    }
    if (!loading && hasMore) {
      return "Scroll Down to Load More";
    }
    if (!loading && !hasMore) {
      return "";
    }
  }, [hasMore, loading]);
  if (!visible) {
    return null;
  }
  return <div className={className} ref={ref} data-sentry-component="InfiniteScrollLoadingIndicator" data-sentry-source-file="infinite-scroll.tsx">
      {content}
    </div>;
}

/**
 * A loading indicator for the InfiniteScroll component with a forwarded reference.
 */
const InfiniteScrollLoadingIndicatorWithReference = forwardRef<HTMLDivElement, InfiniteScrollLoadingIndicatorProps>(InfiniteScrollLoadingIndicator);

/**
 * Properties for the InfiniteScroll component.
 */
export type InfiniteScrollProps = {
  /**
   * The class name to apply to the container.
   */
  className?: HtmlHTMLAttributes<HTMLDivElement>["className"];
  /**
   * Indicates whether there are more items to load.
   */
  hasMore?: InfiniteScrollLoadingIndicatorProps["hasMore"];
  /**
   * Hides the status indicator at the bottom of the container.
   * This is hides the "No more items" message and the loading indicator.
   */
  hideStatusIndicator?: boolean;
  /**
   * Initial loading state.
   */
  loading?: boolean;
  /**
   * Indicates whether the component is currently loading more items.
   */
  loadingMore?: InfiniteScrollLoadingIndicatorProps["loading"];
  /**
   * A function that will be called when the user scrolls to the bottom of the container.
   */
  onLoadMore?: () => Promise<void>;
};

/**
 * A wrapper component that provides an infinite scroll feature.
 *
 * - Displays the loading indicator when loading and there are more items to load.
 * - Displays a message when there are no more items to load.
 */
export function InfiniteScroll({
  children,
  className,
  hasMore,
  hideStatusIndicator,
  loading,
  loadingMore: loadingMoreData,
  onLoadMore
}: PropsWithChildren<InfiniteScrollProps>) {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================
  /**
   * A reference to the loading indicator element.
   * This will be used to determine when the loading indicator is visible in the viewport.
   */
  const loadingIndicatorRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  // ===========================================================================
  // ===========================================================================
  // Functions
  // ===========================================================================
  // ===========================================================================

  /**
   * The callback function for the IntersectionObserver.
   */
  const intersectionObserverCallback = useCallback<IntersectionObserverCallback>(async entries => {
    const loadingIndicator = loadingIndicatorRef.current;
    if (!loadingIndicator || loading || loadingMoreData || !hasMore) return;
    for (const entry of entries) {
      /**
       * Load more items if there are more records and the records are not
       * currently being loaded.
       */
      if (entry.isIntersecting) {
        // Scroll the target out of view
        entry.target.scrollIntoView({
          behavior: "smooth",
          block: "end"
        });

        // Load more records.
        await onLoadMore?.();
      }
    }
  }, [hasMore, loading, loadingMoreData, onLoadMore]);

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  /**
   * Load the next page when the user scrolls to the bottom of the container and
   * made the loading indicator visible.
   */
  useEffect(() => {
    const loadingIndicator = loadingIndicatorRef.current;
    if (!loadingIndicator) return;

    /**
     * Create an intersection observer to observe the loading indicator.
     */
    const intersectionObsever = new IntersectionObserver(intersectionObserverCallback, {
      root: containerRef.current,
      rootMargin: "100px"
    });

    /**
     * Attach the intersection observer to the loading indicator.
     */
    if (intersectionObsever) {
      intersectionObsever.observe(loadingIndicator);
    }
    return () => {
      /**
       * Detach the intersection observer from the loading indicator.
       */
      if (loadingIndicator) {
        intersectionObsever.unobserve(loadingIndicator);
      }
    };
  }, [intersectionObserverCallback]);

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  if (loading) {
    <div className={className} style={{
      overflowY: "auto",
      scrollBehavior: "smooth"
    }}>
      <Spin size="large" spinning />
    </div>;
  }
  return <div className={className} ref={containerRef} style={{
    overflowY: "auto",
    scrollBehavior: "smooth"
  }} data-sentry-component="InfiniteScroll" data-sentry-source-file="infinite-scroll.tsx">
      {children}
      <InfiniteScrollLoadingIndicatorWithReference ref={loadingIndicatorRef} hasMore={hasMore} loading={loading || loadingMoreData} visible={!hideStatusIndicator} data-sentry-element="InfiniteScrollLoadingIndicatorWithReference" data-sentry-source-file="infinite-scroll.tsx" />
    </div>;
}
export default InfiniteScroll;