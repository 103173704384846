"use client";

/**
 * Third-party library.
 */
import { FetchResult } from "@apollo/client";
import { FunctionComponent, PropsWithChildren, createContext, useCallback, useContext, useState } from "react";

/**
 * Project components.
 */
import { SystemPreferenceUpdateMutation, useSystemPreferenceQuery, useSystemPreferenceUpdateMutation } from "@/components/client/graphql";
import { SystemPreferenceKey } from "@/components/common/system-preference";
import { BusinessStatus, CallRecording, SessionInitiationProtocol } from "@/components/common/system-preference/types";

/**
 * Type definition of the context value.
 */
type SystemPreferenceContextValue = {
  /**
   * Business status.
   * - open: Accepts incoming client calls.
   * - closed: Rejects incoming client calls.
   */
  businessStatus: BusinessStatus | null;
  /**
   * The Business Status is still being retrieved by the service.
   */
  businessStatusLoading: boolean;
  /**
   * Session Initiation Protocol (SIP).
   * Only affect outbound calls.
   *
   * - enabled: Uses the United States phone number for outbound calls.
   * - disabled: Uses the Singapore phone number for outbound calls.
   */
  sessionInitationProtocol: SessionInitiationProtocol | null;
  /**
   * The Session Initation Protocol is still being retrieved by the service.
   */
  sessionInitationProtocolLoading: boolean;
  /**
   * Call recordings.
   * - enabled: Call recordings are enabled.
   * - disabled: Call recordings are disabled.
   */
  callRecordings: CallRecording | null;
  /**
   * The Call Recordings is still being retrieved by the service.
   */
  callRecordingsLoading: boolean;
  /**
   * Updates the Business Status system preference.
   */
  updateBusinessStatus: (args: {
    /**
     * The new value of the Business Status.
     */
    businessStatus: BusinessStatus;
  }) => void;
  /**
   * Updates the Session Initiation Protocol system preference.
   */
  updateSessionInitiationProtocol: (args: {
    /**
     * The new value of the Session Initiation Protocol.
     */
    sessionInitationProtocol: SessionInitiationProtocol;
  }) => Promise<FetchResult<SystemPreferenceUpdateMutation>>;
  /**
   * Updates the Call Recordings system preference.
   */
  updateCallRecordings: (args: {
    /**
     * The new value of the Call Recordings.
     */
    callRecordings: CallRecording;
  }) => Promise<FetchResult<SystemPreferenceUpdateMutation>>;
};

/**
 * Initial value of the context.
 */
const SystemPreferenceContext = createContext<SystemPreferenceContextValue>({
  businessStatus: null,
  businessStatusLoading: true,
  sessionInitationProtocol: null,
  sessionInitationProtocolLoading: true,
  callRecordings: null,
  callRecordingsLoading: true,
  updateBusinessStatus: () => {},
  updateSessionInitiationProtocol: () => Promise.resolve({}),
  updateCallRecordings: () => Promise.resolve({})
});

/**
 * Use this hook to access the context values.
 */
export const useSystemPreferenceContext = () => useContext(SystemPreferenceContext);

/**
 * Provider component. Wrap around your components provider to allow components
 * access to the context.
 */
export const SystemPreferenceContextProvider: FunctionComponent<PropsWithChildren> = ({
  children
}) => {
  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  const [businessStatus, setBusinessStatus] = useState<SystemPreferenceContextValue["businessStatus"]>(null);
  const [sessionInitationProtocol, setSessionInitationProtocol] = useState<SystemPreferenceContextValue["sessionInitationProtocol"]>(null);

  // ===========================================================================
  // ===========================================================================
  // Operations
  // ===========================================================================
  // ===========================================================================

  // ===========================================================================
  // Business Status
  // ===========================================================================

  const {
    loading: loadingBusinessStatus
  } = useSystemPreferenceQuery({
    variables: {
      filter: {
        key: SystemPreferenceKey.BUSINESS_STATUS
      }
    },
    onCompleted: data => {
      setBusinessStatus(data.systemPreference?.value as BusinessStatus);
    }
  });
  const [updateBusinessStatus, {
    loading: updatingBusinessStatus
  }] = useSystemPreferenceUpdateMutation({
    onCompleted: data => {
      setBusinessStatus(data.systemPreferenceUpdate?.value as BusinessStatus);
    }
  });

  // ===========================================================================
  // Session Initiation Protocol
  // ===========================================================================

  const {
    loading: loadingSessionInitiationProtocol
  } = useSystemPreferenceQuery({
    variables: {
      filter: {
        key: SystemPreferenceKey.SESSION_INITIATION_PROTOCOL
      }
    },
    onCompleted: data => {
      setSessionInitationProtocol(data.systemPreference?.value as SessionInitiationProtocol);
    }
  });
  const [updateSessionInitiationProtocol, {
    loading: updatingSessionInitiationProtocol
  }] = useSystemPreferenceUpdateMutation({
    onCompleted: data => {
      setSessionInitationProtocol(data.systemPreferenceUpdate?.value as SessionInitiationProtocol);
    }
  });

  // ===========================================================================
  // Call Recordings
  // ===========================================================================

  const callRecordingsSystemPreferenceQuery = useSystemPreferenceQuery({
    variables: {
      filter: {
        key: SystemPreferenceKey.CALL_RECORDING
      }
    }
  });
  const [updateCallRecordings, {
    loading: updatingCallRecordings
  }] = useSystemPreferenceUpdateMutation({
    onCompleted: data => {
      callRecordingsSystemPreferenceQuery.updateQuery(_ => {
        return {
          systemPreference: data.systemPreferenceUpdate
        };
      });
    }
  });

  // ===========================================================================
  // ===========================================================================
  // Functions
  // ===========================================================================
  // ===========================================================================

  const _updateBusinessStatus = useCallback(({
    businessStatus
  }: {
    /**
     * The business status to set.
     */
    businessStatus?: BusinessStatus;
  }) => {
    if (updatingBusinessStatus) {
      console.warn("Business status is currently being updated.");
      return;
    }
    if (!businessStatus) {
      throw new Error("Business status is required.");
    }
    updateBusinessStatus({
      variables: {
        input: {
          key: SystemPreferenceKey.BUSINESS_STATUS,
          value: businessStatus
        }
      }
    });
  }, [updateBusinessStatus, updatingBusinessStatus]);
  const _updateSessionInitiationProtocol = useCallback(({
    sessionInitationProtocol
  }: {
    sessionInitationProtocol?: SessionInitiationProtocol;
  }) => {
    if (!sessionInitationProtocol) {
      throw new Error("Business status is required.");
    }
    return updateSessionInitiationProtocol({
      variables: {
        input: {
          key: SystemPreferenceKey.SESSION_INITIATION_PROTOCOL,
          value: sessionInitationProtocol
        }
      }
    });
  }, [updateSessionInitiationProtocol]);
  const _updateCallRecordings = useCallback(({
    callRecordings
  }: {
    callRecordings?: CallRecording;
  }) => {
    if (!callRecordings) {
      throw new Error("Call recordings is required.");
    }
    return updateCallRecordings({
      variables: {
        input: {
          key: SystemPreferenceKey.CALL_RECORDING,
          value: callRecordings
        }
      }
    });
  }, [updateCallRecordings]);

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return <SystemPreferenceContext.Provider value={{
    businessStatus,
    businessStatusLoading: loadingBusinessStatus || updatingBusinessStatus,
    sessionInitationProtocol,
    sessionInitationProtocolLoading: loadingSessionInitiationProtocol || updatingSessionInitiationProtocol,
    callRecordings: callRecordingsSystemPreferenceQuery.data?.systemPreference?.value as CallRecording ?? null,
    callRecordingsLoading: callRecordingsSystemPreferenceQuery.loading || updatingCallRecordings,
    updateBusinessStatus: _updateBusinessStatus,
    updateSessionInitiationProtocol: _updateSessionInitiationProtocol,
    updateCallRecordings: _updateCallRecordings
  }} data-sentry-element="unknown" data-sentry-component="SystemPreferenceContextProvider" data-sentry-source-file="system-preference-context.tsx">
      {children}
    </SystemPreferenceContext.Provider>;
};