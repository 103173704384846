import { TwilioConferencePrefix } from "./enumerations";

/**
 * Get the conference friendly name.
 *
 * @returns The conference friendly name.
 */
export function getFriendlyName(args: {
  /**
   * Phone number of the customer.
   *
   * We are using the customer phone number as a part of the conference friendly
   * name.
   */
  customerPhoneNumber: string;
}) {
  if (!args.customerPhoneNumber) {
    throw new Error("Customer phone number is required.");
  }

  return `${TwilioConferencePrefix.CONFERENCE}${args.customerPhoneNumber}`;
}
