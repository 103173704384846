"use client";

/**
 * Third-party libraries.
 */
import { Call, Device } from "@twilio/voice-sdk";
import { notification } from "antd";
import React, { PropsWithChildren, useCallback, useEffect, useState } from "react";

/**
 * Project components.
 */
import { useAuthenticationContext } from "@/components/client/authentication";
import { useApplicationContext } from "@/components/client/context";
import { UserAvailabilityStatus } from "@/components/client/graphql";
import { TwilioCallEvent, useTwilioDevice } from "@/components/client/twilio";
import { TwilioCallCustomParameterKey } from "@/components/common/twilio/enumerations";
import { CallCustomParameters } from "@/components/common/twilio/types";

/**
 * Arguments for calling a number or another Twilio device client.
 */
type ConnectToConferenceArgs = {
  /**
   * ID of the call record in the database.
   */
  callId: string;
  /**
   * Phone number or Friendly name of the conference.
   *
   * We are using the customer phone number.
   *
   * @example
   * conference:+6512345678
   */
  conferenceFriendlyName: string;
};
export type CallWithCustomProperties = Call & {
  /**
   * Date the call was initiated.
   */
  date?: Date;
  /**
   * Indicates whether the call is muted.
   */
  muted?: boolean;
};
type CallIdArgs = {
  /**
   * ID of the call to get.
   *
   * This is the internal database call ID.
   */
  callId: string;
};

/**
 * Twilio context.
 *
 * Provides Twilio device, call states and functions:
 * - Twilio call actions:
 *    - Get call.
 *    - Check if call is muted.
 *    - Mute call.
 * - Twilio call states:
 *    - Connected calls.
 * - Twilio device actions:
 *    - Register device.
 *    - Unregister device.
 * - Twilio device states
 *    - Device instance.
 *    - Device error.
 *    - Device is initializing.
 *    - Device is registered.
 *    - Device is registering.
 */
export type TwilioContext = {
  // ===========================================================================
  // Twilio device states.
  // ===========================================================================
  /**
   * Twilio device.
   *
   * @see https://www.twilio.com/docs/voice/sdks/javascript/twiliodevice
   */
  device: Device | null;
  /**
   * Twilio device error.
   */
  deviceError: unknown;
  /**
   * Twilio device is loading.
   */
  deviceInitializing: boolean;
  /**
   * Twilio device is registered.
   */
  deviceRegistered: boolean;
  /**
   * Twilio device is registering.
   */
  deviceRegistering: boolean;
  /**
   * Register the Device instance with Twilio, allowing it to receive incoming calls.
   *
   * This will open a signaling WebSocket, so the browser tab may show the 'recording' icon.
   *
   * It's not necessary to call device.register() in order to make outgoing calls.
   */
  registerDevice: () => Promise<void>;
  /**
   * Unregister the Device instance with Twilio. This will prevent the Device
   * instance from receiving incoming calls.
   */
  unregisterDevice: () => Promise<void>;
  // ===========================================================================
  // Twilio call functions and states.
  // ===========================================================================
  /**
   * Active twilio call.
   */
  calls: Call[];
  /**
   * Call a number or another Twilio device client.
   *
   * @see https://www.twilio.com/docs/voice/sdks/javascript/twiliodevice#deviceconnectconnectoptions
   */
  connectToConference: (args: ConnectToConferenceArgs) => Promise<Call | undefined>;
  /**
   * Checks if the call is muted.
   */
  isMuted: (args: CallIdArgs) => boolean;
  /**
   * Get the call with the specified call SID.
   */
  getCall: (args: CallIdArgs) => CallWithCustomProperties | null;
  /**
   * Mute/Unmute the selected call.
   */
  toggleMute: (args: CallIdArgs) => void;
};

/**
 * Twilio related context.
 */
const TwilioContext = React.createContext<TwilioContext>({
  // ===========================================================================
  // Twilio device states.
  // ===========================================================================
  device: null,
  deviceError: null,
  deviceInitializing: true,
  deviceRegistered: false,
  deviceRegistering: false,
  registerDevice: () => Promise.resolve(),
  unregisterDevice: () => {
    return Promise.resolve();
  },
  // ===========================================================================
  // Twilio call functions and states.
  // ===========================================================================
  calls: [],
  connectToConference: () => Promise.resolve(undefined),
  isMuted: () => false,
  getCall: () => null,
  toggleMute: () => {}
});

/**
 * Use Twilio context.
 */
export const useTwilioContext = () => {
  return React.useContext(TwilioContext);
};

/**
 * Twilio context provider.
 */
export const TwilioContextProvider = ({
  children
}: PropsWithChildren) => {
  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  /**
   * The active Twilio call.
   * The purpose of this variable is to store one or more active calls of an
   * agent. This is so we can easily access them if we need to interact with them.
   *
   * @see https://www.twilio.com/docs/voice/sdks/javascript/twiliocall
   */
  const [calls, setCalls] = useState<CallWithCustomProperties[]>([]);

  /**
   * Stores the Call ID of the muted calls.
   */
  const [mutedCalls, setMutedCalls] = useState<string[]>([]);

  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  /**
   * User availability status.
   */
  const {
    setUserAvailabilityStatus
  } = useApplicationContext();
  const {
    user
  } = useAuthenticationContext();
  const {
    destroyDevice,
    device: device,
    error: deviceError,
    initialize: initializeDevice,
    initializing: deviceInitializing,
    registered: deviceRegistered,
    registering: deviceRegistering,
    registerDevice,
    unregisterDevice
  } = useTwilioDevice({
    callback: {
      destroyed: () => {
        console.log("Twilio device destroyed.");
        setUserAvailabilityStatus({
          status: UserAvailabilityStatus.Offline
        });
      },
      error: error => {
        console.error("Twilio device error:", error);
      },
      incoming: ({
        call
      }) => {
        console.log("Incoming call:", call);

        /**
         * Add the call to the list of connected calls.
         */
        addCall({
          call
        });
      },
      registered: () => {
        console.log("Twilio device registered.");
      },
      registering: () => {
        console.log("Twilio device registering.");
      },
      token_will_expire: () => {
        console.log("Twilio device token will expire.");
      },
      unregistered: () => {
        console.log("Twilio device unregistered.");
      }
    }
  });

  // ===========================================================================
  // ===========================================================================
  // Functions
  // ===========================================================================
  // ===========================================================================

  /**
   * Connect the user to an existing conference call.
   *
   * This will be handled next by the ApiRoute.TWILIO_VOICE_ROUTER.
   *
   * We are only currently using this when a user accepts an incoming call.
   */
  const connectToConference: TwilioContext["connectToConference"] = async function (args: ConnectToConferenceArgs) {
    /**
     * Connect the user to an existing conference call.
     *
     * This will be handled by the
     *
     * @see https://www.twilio.com/docs/voice/sdks/javascript/twiliodevice#deviceconnectconnectoptions
     */
    const _call = await device?.connect({
      params: {
        To: args.conferenceFriendlyName,
        /**
         * Call ID.
         *
         * We added it here so we can match the Twilio call with the call log
         * we have internally.
         */
        [TwilioCallCustomParameterKey.CALL_ID]: args.callId
      }
    });
    if (!_call) {
      return undefined;
    }
    addCall({
      call: _call,
      automaticallyAccept: false
    });
    return _call;
  };
  type RemoveCallArgs = {
    /**
     * Twilio call instance.
     */
    call: Call;
  };

  /**
   * Remove the call from the list of connected calls and the muted calls as
   * applicable.
   */
  const removeCall = useCallback(({
    call
  }: RemoveCallArgs) => {
    // Remove the call from the list of connected calls.
    setCalls(previousCalls => previousCalls.filter(previousCall => previousCall.parameters.CallSid !== call.parameters.CallSid) ?? []);

    // Remove the call from the muted calls if it exists.
    setMutedCalls(previousMutedCalls => previousMutedCalls.filter(mutedCallSid => mutedCallSid !== call.parameters.CallSid));
  }, []);

  /**
   * Arguments for setting the call event listeners.
   */
  type SetCallEventListenerArgs = {
    /**
     * Twilio call instance.
     */
    call: Call;
  };

  /**
   * Attach event listeners to the call.
   */
  const setCallEventListeners = useCallback(({
    call
  }: SetCallEventListenerArgs) => {
    console.log("Setting call event listeners.", {
      call
    });
    const onCallAccept = (call: Call) => {
      console.log("Call accepted. Call SID: ", call.parameters.CallSid);
    };
    const onCallCancel = () => {
      console.log("Call canceled.");
      removeCall({
        call
      });
    };
    const onCallDisconnect = () => {
      console.log("Call disconnected.");
      removeCall({
        call
      });
    };
    const onCallError = (error: unknown) => {
      console.error("Call error.", error);
    };
    const onCallMute = (muted: boolean, call: Call) => {
      console.log("Call muted:", muted);
      const callId = call.customParameters.get(TwilioCallCustomParameterKey.CALL_ID) as CallCustomParameters[TwilioCallCustomParameterKey.CALL_ID];

      // Add the call SID to the muted calls list.
      if (muted) {
        setMutedCalls(previousMutedCalls => [...previousMutedCalls, callId]);
      }
      // Remove the call SID from the muted calls list.
      else {
        setMutedCalls(previousMutedCalls => previousMutedCalls.filter(mutedCallId => mutedCallId !== callId));
      }
    };
    const onCallReconnected = () => {
      console.log("Call reconnected.");
    };
    const onCallReconnecting = (error: unknown) => {
      console.log("Call reconnecting.", error);
    };
    const onCallReject = () => {
      console.log("Call rejected.");
      removeCall({
        call
      });
    };
    const onCallRing = (hasEarlyMedia: boolean) => {
      console.log("Call ringing.");

      // if (!hasEarlyMedia) {
      //   // Do not play the outgoing ringing sound
      // }
    };
    call.on(TwilioCallEvent.ACCEPT, onCallAccept);
    call.on(TwilioCallEvent.CANCEL, onCallCancel);
    call.on(TwilioCallEvent.DISCONNECT, onCallDisconnect);
    call.on(TwilioCallEvent.ERROR, onCallError);
    call.on(TwilioCallEvent.MUTE, onCallMute);
    call.on(TwilioCallEvent.RECONNECTED, onCallReconnected);
    call.on(TwilioCallEvent.RECONNECTING, onCallReconnecting);
    call.on(TwilioCallEvent.REJECT, onCallReject);
    call.on(TwilioCallEvent.RINGING, onCallRing);
  }, [removeCall]);
  type AddCallArgs = {
    /**
     * Twilio call instance.
     */
    call: Call;
    /**
     * Indicates whether the call should be automatically accepted.
     */
    automaticallyAccept?: boolean;
  };

  /**
   * Add the call to the list of connected calls.
   */
  const addCall = useCallback(({
    automaticallyAccept = true,
    call
  }: AddCallArgs) => {
    const customCall = call as CallWithCustomProperties;

    // Append the date when the call was initiated to the call object.
    customCall.date = new Date();

    // Track if the call is muted.
    customCall.muted = call.isMuted();
    setCallEventListeners({
      call: customCall
    });
    setCalls(previousCalls => [...previousCalls, customCall]);
    if (automaticallyAccept) {
      /**
       * Accepts incoming call from the Twilio server.
       *
       * For incoming calls:
       * - This is done because incoming calls are queued in the server and is
       * later connected to the user.
       *
       * For outgoing calls:
       * - The backend service initiates a call to the client and then connects
       * it to the user.
       */
      call.accept();
    }
  }, [setCallEventListeners]);

  /**
   * Get the Twilio call associated with the provided call SID.
   *
   * @returns The Twilio call instance or null.
   */
  const getCall = useCallback<TwilioContext["getCall"]>(({
    callId
  }) => {
    if (!calls?.length) {
      return null;
    }

    /**
     * Twilio call associated with this communication log card.
     */
    return calls.find(twilioCall => {
      /**
       * This is defined in the `params` property of the `device.connect()`
       * function.
       */
      const twilioCallCustomCallId = twilioCall.customParameters.get(TwilioCallCustomParameterKey.CALL_ID) as CallCustomParameters[TwilioCallCustomParameterKey.CALL_ID];
      return twilioCallCustomCallId === callId;
    }) ?? null;
  }, [calls]);

  /**
   * Checks if the call is muted.
   */
  const isMuted = useCallback<TwilioContext["isMuted"]>(({
    callId
  }) => {
    return mutedCalls.includes(callId);
  }, [mutedCalls]);

  /**
   * Mutes a selected call.
   */
  const toggleMute = useCallback<TwilioContext["toggleMute"]>(({
    callId
  }) => {
    /**
     * Twilio call instance.
     */
    const twilioCall = getCall({
      callId
    });
    if (!twilioCall) {
      return;
    }
    const newMuteStatus = !twilioCall.isMuted();
    twilioCall.mute(newMuteStatus);
  }, [getCall]);

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  /**
   * Initialize Twilio device if it hasn't been intiialized yet.
   */
  useEffect(() => {
    /**
     * Only authenticated users are allowed to use Twilio device.
     *
     * Make sure that only one device is initialized.
     */
    if (!user || device || deviceInitializing) {
      return;
    }
    initializeDevice();
    return () => {
      destroyDevice();
    };
  }, [destroyDevice, device, deviceInitializing, initializeDevice, user]);

  /**
   * Register or unregister Twilio device based on user availability status.
   */
  useEffect(() => {
    // Prevent any succeeding code from executing if device is not yet initialized.
    if (!device || deviceInitializing || deviceRegistering) {
      return;
    }

    // Register device if user is available and device is not registered.
    if (!deviceRegistered) {
      registerDevice();
    }
  }, [device, deviceInitializing, deviceRegistered, registerDevice, deviceRegistering]);

  /**
   * Display a notification when there's a Twilio device error.
   */
  useEffect(() => {
    // Set the user to offline if there's any Twilio device error.
    if (deviceError) {
      notification.error({
        message: "Twilio device error.",
        description: "An error occurred while updating Twilio device.",
        showProgress: true,
        pauseOnHover: true,
        key: "twilio-device-error"
      });
    }
  }, [deviceError]);

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return <TwilioContext.Provider value={{
    // ===========================================================================
    // Twilio device states.
    // ===========================================================================
    device,
    deviceError,
    deviceInitializing,
    deviceRegistered,
    deviceRegistering,
    registerDevice,
    unregisterDevice,
    // ===========================================================================
    // Twilio call functions and states.
    // ===========================================================================
    calls,
    connectToConference,
    isMuted,
    getCall,
    toggleMute
  }} data-sentry-element="unknown" data-sentry-component="TwilioContextProvider" data-sentry-source-file="twilio-context.tsx">
      {children}
    </TwilioContext.Provider>;
};