import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTimeISO: { input: any; output: any; }
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

/** Business Hours settings for each day in a week. This would contain 7 documents only (1 for each day of the week). */
export type BusinessHour = {
  __typename?: 'BusinessHour';
  /** When true, the logic should be based on startTime and endTime. When false, business hours are overriden and closed for that day. */
  active: Scalars['Boolean']['output'];
  /** Day of the week (Monday to Sunday). */
  day: DayOfWeek;
  /**
   * End time in UTC+00:00. (Datetime format jump the date to a relevant time at runtime).
   * It can never be smaller than startTime. It could also be the following day after startTime (e.g. 2024-01-02).
   * Use 2024-01-01T00:00:00+08:00 as the base date for the first record.
   */
  endTime: Scalars['DateTimeISO']['output'];
  /** Unique identifier of the System Preference. */
  id: Scalars['String']['output'];
  /**
   * Start time in UTC+00:00. (DateTime format jump the date to a relevant time at runtime).
   * Use 2024-01-01T00:00:00+08:00 as the base date for the first record.
   */
  startTime: Scalars['DateTimeISO']['output'];
};

/** Business hour event */
export type BusinessHourEvent = {
  __typename?: 'BusinessHourEvent';
  /** Unique ID of the event. */
  id: Scalars['String']['output'];
  /** Payload of the event. */
  payload: BusinessHourEventPayload;
  /** Type of event that was published. */
  type: Scalars['String']['output'];
};

/** Business hour event payload */
export type BusinessHourEventPayload = {
  __typename?: 'BusinessHourEventPayload';
  /** Business hours that were updated */
  businessHours: Array<BusinessHour>;
};

/** Input for updating all 7 days of business hour settings. */
export type BusinessHoursUpdateInput = {
  /** The new business hours. Can be inclusive of all or not. If the day exists, it will be updated. */
  businessHours?: InputMaybe<Array<NewBusinessHour>>;
};

/** Stores the incoming and outgoing call details. */
export type Call = {
  __typename?: 'Call';
  /** Twilio Call SID. */
  callSid: Scalars['String']['output'];
  /**
   * Twilio Conference SID.
   * This is only available when the call was put in a conference.
   */
  conferenceSid?: Maybe<Scalars['String']['output']>;
  /** Date and time the call was created. */
  date: Scalars['DateTimeISO']['output'];
  /** Date and time the call ended. */
  dateEnded?: Maybe<Scalars['DateTimeISO']['output']>;
  /** Date and time the call was first accepted. */
  dateStarted?: Maybe<Scalars['DateTimeISO']['output']>;
  /** Direction of the call. */
  direction: CallDirection;
  /** Duration of the call in seconds. This is the number of seconds from the time it was accepted until it ended. */
  duration: Scalars['Int']['output'];
  /** Indicates which end terminated the call. */
  endedBy?: Maybe<CallEndedBy>;
  /** Phone number or client name the call is coming from. */
  from: Scalars['String']['output'];
  /** Resolver for the 'from' contacts related to the call. */
  fromContact?: Maybe<Contact>;
  fromContactId?: Maybe<Scalars['String']['output']>;
  /** Unique identifier of the Call. */
  id: Scalars['String']['output'];
  missedCallId?: Maybe<Scalars['String']['output']>;
  /** The embedded schema of a completed recording of a call. Can be used to get the recordingSid and download the recording from Google Cloud Storage. */
  recording?: Maybe<CallRecording>;
  /** Resolver for routings related to a call. */
  routings: Array<CallRouting>;
  /** Status of the call. */
  status: CallStatus;
  /** A summary of what the call was about. */
  summary?: Maybe<Scalars['String']['output']>;
  /** Resolver for tasks related to a call. */
  tasks: Array<Task>;
  /** Phone number or client name the call is directed to. */
  to: Scalars['String']['output'];
  /** Resolver for the 'to' contacts related to the call. */
  toContact?: Maybe<Contact>;
  toContactId?: Maybe<Scalars['String']['output']>;
  /** Resolver for user related to a call. */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']['output']>;
};


/** Stores the incoming and outgoing call details. */
export type CallFromContactArgs = {
  where?: InputMaybe<ContactWhereInput>;
};


/** Stores the incoming and outgoing call details. */
export type CallRoutingsArgs = {
  cursor?: InputMaybe<CallRoutingWhereUniqueInput>;
  distinct?: InputMaybe<Array<CallRoutingScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CallRoutingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CallRoutingWhereInput>;
};


/** Stores the incoming and outgoing call details. */
export type CallTasksArgs = {
  cursor?: InputMaybe<TaskWhereUniqueInput>;
  distinct?: InputMaybe<Array<TaskScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TaskOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TaskWhereInput>;
};


/** Stores the incoming and outgoing call details. */
export type CallToContactArgs = {
  where?: InputMaybe<ContactWhereInput>;
};


/** Stores the incoming and outgoing call details. */
export type CallUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};

/** Input for accepting a call. */
export type CallAcceptInput = {
  /** Call ID. */
  callId: Scalars['String']['input'];
};

/** Input for assign call mutation. */
export type CallAssignInput = {
  /** Call ID. */
  callId: Scalars['String']['input'];
  /** ID of the user to assign the call to. */
  userId: Scalars['String']['input'];
};

/** Input for updating the status of a call to 'completed'. */
export type CallCompleteInput = {
  /** Call ID. */
  callId: Scalars['String']['input'];
};

/** Input for creating a call. */
export type CallCreateInput = {
  /** Phone number or client name the call is directed to. */
  to: Scalars['String']['input'];
};

/** Direction of the call. */
export enum CallDirection {
  Inbound = 'INBOUND',
  Outbound = 'OUTBOUND'
}

/** Indicates which end terminated the call. */
export enum CallEndedBy {
  Agent = 'AGENT',
  Client = 'CLIENT'
}

/** Input for call event subscription. */
export type CallEventInput = {
  /** ID of the user subscribing to the call event. Provide this only if you want to subscribe to all completed calls alongside the uncompleted assigned calls to a specific user. */
  userId: Scalars['String']['input'];
};

/** Input for getting a single call record. */
export type CallFilterInput = {
  /** ID of the call to get. */
  id: Scalars['String']['input'];
};

export type CallListRelationFilter = {
  every?: InputMaybe<CallWhereInput>;
  none?: InputMaybe<CallWhereInput>;
  some?: InputMaybe<CallWhereInput>;
};

/** Model for recording the missed calls grouped by the customer phone number. */
export type CallMissedGroup = {
  __typename?: 'CallMissedGroup';
  /** Resolver for the 'calls' related to the missed calls group. */
  calls?: Maybe<Array<Call>>;
  /** Unique identifier of the Call. */
  id: Scalars['String']['output'];
  /** Id of the last call with the customer. */
  lastCallId: Scalars['String']['output'];
  /** Last interaction date with customer. */
  lastMissedDate: Scalars['DateTimeISO']['output'];
  /** The customer phone number of the missed call group */
  phoneNumber: Scalars['String']['output'];
};


/** Model for recording the missed calls grouped by the customer phone number. */
export type CallMissedGroupCallsArgs = {
  cursor?: InputMaybe<CallWhereUniqueInput>;
  distinct?: InputMaybe<Array<CallScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CallOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CallWhereInput>;
};

/** Input for the call missed group added event. */
export type CallMissedGroupAddedEventInput = {
  /** ID of the user subscribing to the call missed group add event. Provide this only if you want to subscribe to all completed calls alongside the uncompleted assigned calls to a specific user. */
  userId: Scalars['String']['input'];
};

/** Input for assign call missed mutation. */
export type CallMissedGroupAssignInput = {
  /** Call ID. */
  callId: Scalars['String']['input'];
  /** ID of the user to assign the call to. */
  userId: Scalars['String']['input'];
};

export type CallMissedGroupNullableRelationFilter = {
  is?: InputMaybe<CallMissedGroupWhereInput>;
  isNot?: InputMaybe<CallMissedGroupWhereInput>;
};

export type CallMissedGroupOrderByWithRelationInput = {
  calls?: InputMaybe<CallOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  lastCallId?: InputMaybe<SortOrder>;
  lastMissedDate?: InputMaybe<SortOrder>;
  phoneNumber?: InputMaybe<SortOrder>;
};

/** Input for unassign call missed mutation. */
export type CallMissedGroupUnassignInput = {
  /** Call ID. */
  callId: Scalars['String']['input'];
};

export type CallMissedGroupWhereInput = {
  AND?: InputMaybe<Array<CallMissedGroupWhereInput>>;
  NOT?: InputMaybe<Array<CallMissedGroupWhereInput>>;
  OR?: InputMaybe<Array<CallMissedGroupWhereInput>>;
  calls?: InputMaybe<CallListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  lastCallId?: InputMaybe<StringFilter>;
  lastMissedDate?: InputMaybe<DateTimeFilter>;
  phoneNumber?: InputMaybe<StringFilter>;
};

export type CallOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CallOrderByWithRelationInput = {
  callSid?: InputMaybe<SortOrder>;
  conferenceSid?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  dateEnded?: InputMaybe<SortOrder>;
  dateStarted?: InputMaybe<SortOrder>;
  direction?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  endedBy?: InputMaybe<SortOrder>;
  from?: InputMaybe<SortOrder>;
  fromContact?: InputMaybe<ContactOrderByWithRelationInput>;
  fromContactId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  missedCall?: InputMaybe<CallMissedGroupOrderByWithRelationInput>;
  missedCallId?: InputMaybe<SortOrder>;
  recording?: InputMaybe<CallRecordingOrderByInput>;
  routings?: InputMaybe<CallRoutingOrderByRelationAggregateInput>;
  status?: InputMaybe<SortOrder>;
  summary?: InputMaybe<SortOrder>;
  tasks?: InputMaybe<TaskOrderByRelationAggregateInput>;
  to?: InputMaybe<SortOrder>;
  toContact?: InputMaybe<ContactOrderByWithRelationInput>;
  toContactId?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

/** The embedded schema of a completed recording of a call. Can be used to get the recordingSid and download the recording from Google Cloud Storage. */
export type CallRecording = {
  __typename?: 'CallRecording';
  /** The unique identifier of the Account responsible for this recording. */
  accountSid: Scalars['String']['output'];
  /** The number of channels in the final recording file as an integer. Possible values are 1, 2. */
  channels: Scalars['Int']['output'];
  /** The length of the recording, in seconds. */
  duration: Scalars['Int']['output'];
  /** The unique identifier for the recording (can be used to get a signedURL of the recording from Google Cloud Storage). It is stored as `recordings/<recordingSid>` in GCS. */
  recordingSid: Scalars['String']['output'];
  /** The presigned recording URL of the call. NOTE: This doesn't always mean that the recording is actually on GCS. Make sure to check `recording.synced: true` first before using this. */
  recordingUrl?: Maybe<Scalars['String']['output']>;
  /** The initiation method used to create this recording. For recordings initiated when record is set on <Dial>, DialVerb is returned. */
  source: Scalars['String']['output'];
  /** The timestamp of when the recording started. Can use this with the duration to calculate the end time. */
  startTime: Scalars['DateTimeISO']['output'];
  /**
   * The valid recording statuses that are handled by the recording-status-change callback.
   * Primarily used for letting the user know what's happening with the recording. It always starts with In Progress.
   */
  status: CallRecordingStatus;
  /** Indicates if successfully saved to Google Cloud Storage */
  synced: Scalars['Boolean']['output'];
  /**
   * Third Party Url is the original recording URL where the recording is saved.
   * Currently this is Twilio Recording URL, and the original recording is only available for 1 year from the time of creation.
   * This should be used as a fallback.
   */
  thirdPartyUrl: Scalars['String']['output'];
};

export type CallRecordingNullableCompositeFilter = {
  equals?: InputMaybe<CallRecordingObjectEqualityInput>;
  is?: InputMaybe<CallRecordingWhereInput>;
  isNot?: InputMaybe<CallRecordingWhereInput>;
  isSet?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CallRecordingObjectEqualityInput = {
  accountSid: Scalars['String']['input'];
  channels: Scalars['Int']['input'];
  duration: Scalars['Int']['input'];
  recordingSid: Scalars['String']['input'];
  source: Scalars['String']['input'];
  startTime: Scalars['DateTimeISO']['input'];
  status: CallRecordingStatus;
  synced: Scalars['Boolean']['input'];
  thirdPartyUrl: Scalars['String']['input'];
};

export type CallRecordingOrderByInput = {
  accountSid?: InputMaybe<SortOrder>;
  channels?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  recordingSid?: InputMaybe<SortOrder>;
  source?: InputMaybe<SortOrder>;
  startTime?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  synced?: InputMaybe<SortOrder>;
  thirdPartyUrl?: InputMaybe<SortOrder>;
};

/**
 * The valid recording statuses that are handled by the recording-status-change callback.
 * Primarily used for letting the user know what's happening with the recording.
 * More info: https://help.twilio.com/articles/360014251313
 */
export enum CallRecordingStatus {
  Completed = 'COMPLETED',
  Deleted = 'DELETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS'
}

export type CallRecordingWhereInput = {
  AND?: InputMaybe<Array<CallRecordingWhereInput>>;
  NOT?: InputMaybe<Array<CallRecordingWhereInput>>;
  OR?: InputMaybe<Array<CallRecordingWhereInput>>;
  accountSid?: InputMaybe<StringFilter>;
  channels?: InputMaybe<IntFilter>;
  duration?: InputMaybe<IntFilter>;
  recordingSid?: InputMaybe<StringFilter>;
  source?: InputMaybe<StringFilter>;
  startTime?: InputMaybe<DateTimeFilter>;
  status?: InputMaybe<EnumCallRecordingStatusFilter>;
  synced?: InputMaybe<BoolFilter>;
  thirdPartyUrl?: InputMaybe<StringFilter>;
};

/** Input for rejecting a call. */
export type CallRejectInput = {
  /** Call ID. */
  callId: Scalars['String']['input'];
};

export type CallRelationFilter = {
  is?: InputMaybe<CallWhereInput>;
  isNot?: InputMaybe<CallWhereInput>;
};

export type CallRouting = {
  __typename?: 'CallRouting';
  /** Call that was routed. */
  call: Call;
  callId: Scalars['String']['output'];
  /** Date and time the call was routed. */
  date: Scalars['DateTimeISO']['output'];
  /** Unique identifier of the Call. */
  id: Scalars['String']['output'];
  /** A remark related to the status of the routing. */
  remark?: Maybe<Scalars['String']['output']>;
  /** Status of the call routing. */
  status: CallRoutingStatus;
  /** Task associated to the call routing. */
  tasks: Array<Task>;
  /** User who was assigned to the call. */
  user: User;
  userId: Scalars['String']['output'];
};


export type CallRoutingTasksArgs = {
  cursor?: InputMaybe<TaskWhereUniqueInput>;
  distinct?: InputMaybe<Array<TaskScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TaskOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TaskWhereInput>;
};

export type CallRoutingListRelationFilter = {
  every?: InputMaybe<CallRoutingWhereInput>;
  none?: InputMaybe<CallRoutingWhereInput>;
  some?: InputMaybe<CallRoutingWhereInput>;
};

export type CallRoutingOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CallRoutingOrderByWithRelationInput = {
  call?: InputMaybe<CallOrderByWithRelationInput>;
  callId?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  remark?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  tasks?: InputMaybe<TaskOrderByRelationAggregateInput>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export type CallRoutingRelationFilter = {
  is?: InputMaybe<CallRoutingWhereInput>;
  isNot?: InputMaybe<CallRoutingWhereInput>;
};

export enum CallRoutingScalarFieldEnum {
  CallId = 'callId',
  Date = 'date',
  Id = 'id',
  Remark = 'remark',
  Status = 'status',
  UserId = 'userId'
}

/** Status of a call routing. */
export enum CallRoutingStatus {
  Accepted = 'ACCEPTED',
  Assigned = 'ASSIGNED',
  Dialed = 'DIALED',
  Missed = 'MISSED',
  Rejected = 'REJECTED'
}

/** Input for updating a call routing remark. */
export type CallRoutingUpdateRemarkInput = {
  /** ID of the call routing remark to update. */
  id: Scalars['String']['input'];
  /** Remark to save on the call routing remark. */
  remark: Scalars['String']['input'];
};

export type CallRoutingWhereInput = {
  AND?: InputMaybe<Array<CallRoutingWhereInput>>;
  NOT?: InputMaybe<Array<CallRoutingWhereInput>>;
  OR?: InputMaybe<Array<CallRoutingWhereInput>>;
  call?: InputMaybe<CallRelationFilter>;
  callId?: InputMaybe<StringFilter>;
  date?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  remark?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumCallRoutingStatusFilter>;
  tasks?: InputMaybe<TaskListRelationFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type CallRoutingWhereUniqueInput = {
  AND?: InputMaybe<Array<CallRoutingWhereInput>>;
  NOT?: InputMaybe<Array<CallRoutingWhereInput>>;
  OR?: InputMaybe<Array<CallRoutingWhereInput>>;
  call?: InputMaybe<CallRelationFilter>;
  callId?: InputMaybe<StringFilter>;
  date?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumCallRoutingStatusFilter>;
  tasks?: InputMaybe<TaskListRelationFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export enum CallScalarFieldEnum {
  CallSid = 'callSid',
  ConferenceSid = 'conferenceSid',
  Date = 'date',
  DateEnded = 'dateEnded',
  DateStarted = 'dateStarted',
  Direction = 'direction',
  Duration = 'duration',
  EndedBy = 'endedBy',
  From = 'from',
  FromContactId = 'fromContactId',
  Id = 'id',
  MissedCallId = 'missedCallId',
  Status = 'status',
  Summary = 'summary',
  To = 'to',
  ToContactId = 'toContactId',
  UserId = 'userId'
}

/** Status of a call. */
export enum CallStatus {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Missed = 'MISSED',
  Pending = 'PENDING',
  Queued = 'QUEUED',
  Rejected = 'REJECTED',
  WrappingUp = 'WRAPPING_UP'
}

/** Input for unassign call mutation. */
export type CallUnassignInput = {
  /** Call ID. */
  callId: Scalars['String']['input'];
};

/** Input for creating a call. */
export type CallUpdateInput = {
  /** Call ID. */
  callId: Scalars['String']['input'];
  /** Status of the call to update. */
  status: CallStatus;
};

/** Input for updating the summary of a call. */
export type CallUpdateSummaryInput = {
  /** Call ID. */
  callId: Scalars['String']['input'];
  /** Summary of the call. */
  summary: Scalars['String']['input'];
};

export type CallWhereInput = {
  AND?: InputMaybe<Array<CallWhereInput>>;
  NOT?: InputMaybe<Array<CallWhereInput>>;
  OR?: InputMaybe<Array<CallWhereInput>>;
  callSid?: InputMaybe<StringFilter>;
  conferenceSid?: InputMaybe<StringNullableFilter>;
  date?: InputMaybe<DateTimeFilter>;
  dateEnded?: InputMaybe<DateTimeNullableFilter>;
  dateStarted?: InputMaybe<DateTimeNullableFilter>;
  direction?: InputMaybe<EnumCallDirectionFilter>;
  duration?: InputMaybe<IntFilter>;
  endedBy?: InputMaybe<EnumCallEndedByNullableFilter>;
  from?: InputMaybe<StringFilter>;
  fromContact?: InputMaybe<ContactNullableRelationFilter>;
  fromContactId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  missedCall?: InputMaybe<CallMissedGroupNullableRelationFilter>;
  missedCallId?: InputMaybe<StringNullableFilter>;
  recording?: InputMaybe<CallRecordingNullableCompositeFilter>;
  routings?: InputMaybe<CallRoutingListRelationFilter>;
  status?: InputMaybe<EnumCallStatusFilter>;
  summary?: InputMaybe<StringNullableFilter>;
  tasks?: InputMaybe<TaskListRelationFilter>;
  to?: InputMaybe<StringFilter>;
  toContact?: InputMaybe<ContactNullableRelationFilter>;
  toContactId?: InputMaybe<StringNullableFilter>;
  user?: InputMaybe<UserNullableRelationFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type CallWhereUniqueInput = {
  AND?: InputMaybe<Array<CallWhereInput>>;
  NOT?: InputMaybe<Array<CallWhereInput>>;
  OR?: InputMaybe<Array<CallWhereInput>>;
  callSid?: InputMaybe<Scalars['String']['input']>;
  conferenceSid?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<DateTimeFilter>;
  dateEnded?: InputMaybe<DateTimeNullableFilter>;
  dateStarted?: InputMaybe<DateTimeNullableFilter>;
  direction?: InputMaybe<EnumCallDirectionFilter>;
  duration?: InputMaybe<IntFilter>;
  endedBy?: InputMaybe<EnumCallEndedByNullableFilter>;
  from?: InputMaybe<StringFilter>;
  fromContact?: InputMaybe<ContactNullableRelationFilter>;
  fromContactId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  missedCall?: InputMaybe<CallMissedGroupNullableRelationFilter>;
  missedCallId?: InputMaybe<StringNullableFilter>;
  recording?: InputMaybe<CallRecordingNullableCompositeFilter>;
  routings?: InputMaybe<CallRoutingListRelationFilter>;
  status?: InputMaybe<EnumCallStatusFilter>;
  summary?: InputMaybe<StringNullableFilter>;
  tasks?: InputMaybe<TaskListRelationFilter>;
  to?: InputMaybe<StringFilter>;
  toContact?: InputMaybe<ContactNullableRelationFilter>;
  toContactId?: InputMaybe<StringNullableFilter>;
  user?: InputMaybe<UserNullableRelationFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

/** Model for represending a Call with missed count. */
export type CallWithMissedCount = {
  __typename?: 'CallWithMissedCount';
  /** Twilio Call SID. */
  callSid: Scalars['String']['output'];
  /**
   * Twilio Conference SID.
   * This is only available when the call was put in a conference.
   */
  conferenceSid?: Maybe<Scalars['String']['output']>;
  /** Date and time the call was created. */
  date: Scalars['DateTimeISO']['output'];
  /** Date and time the call ended. */
  dateEnded?: Maybe<Scalars['DateTimeISO']['output']>;
  /** Date and time the call was first accepted. */
  dateStarted?: Maybe<Scalars['DateTimeISO']['output']>;
  /** Direction of the call. */
  direction: CallDirection;
  /** Duration of the call in seconds. This is the number of seconds from the time it was accepted until it ended. */
  duration: Scalars['Int']['output'];
  /** Indicates which end terminated the call. */
  endedBy?: Maybe<CallEndedBy>;
  /** Phone number or client name the call is coming from. */
  from: Scalars['String']['output'];
  /** Resolver for the 'from' contacts related to the call. */
  fromContact?: Maybe<Contact>;
  fromContactId?: Maybe<Scalars['String']['output']>;
  /** Unique identifier of the Call. */
  id: Scalars['String']['output'];
  missedCallId?: Maybe<Scalars['String']['output']>;
  /** Number of accumulated consecutive missed calls from a client. Calls are grouped by the phone number. */
  missedCount: Scalars['Float']['output'];
  /** The embedded schema of a completed recording of a call. Can be used to get the recordingSid and download the recording from Google Cloud Storage. */
  recording?: Maybe<CallRecording>;
  /** Resolver for routings related to a call. */
  routings: Array<CallRouting>;
  /** Status of the call. */
  status: CallStatus;
  /** A summary of what the call was about. */
  summary?: Maybe<Scalars['String']['output']>;
  /** Resolver for tasks related to a call. */
  tasks: Array<Task>;
  /** Phone number or client name the call is directed to. */
  to: Scalars['String']['output'];
  /** Resolver for the 'to' contacts related to the call. */
  toContact?: Maybe<Contact>;
  toContactId?: Maybe<Scalars['String']['output']>;
  /** Resolver for user related to a call. */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']['output']>;
};


/** Model for represending a Call with missed count. */
export type CallWithMissedCountFromContactArgs = {
  where?: InputMaybe<ContactWhereInput>;
};


/** Model for represending a Call with missed count. */
export type CallWithMissedCountRoutingsArgs = {
  cursor?: InputMaybe<CallRoutingWhereUniqueInput>;
  distinct?: InputMaybe<Array<CallRoutingScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CallRoutingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CallRoutingWhereInput>;
};


/** Model for represending a Call with missed count. */
export type CallWithMissedCountTasksArgs = {
  cursor?: InputMaybe<TaskWhereUniqueInput>;
  distinct?: InputMaybe<Array<TaskScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TaskOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TaskWhereInput>;
};


/** Model for represending a Call with missed count. */
export type CallWithMissedCountToContactArgs = {
  where?: InputMaybe<ContactWhereInput>;
};


/** Model for represending a Call with missed count. */
export type CallWithMissedCountUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};

/** Input for getting calls. */
export type CallsFilterInput = {
  /** Filter by `Call.date`. */
  dateRange?: InputMaybe<PartialDateTimeRangeInput>;
  /** Filter for the call's "from" field. */
  from?: InputMaybe<Scalars['String']['input']>;
  /**
   * ID of the user assigned to the ongoing call.
   * - All concluded calls will be included regardless of the user if this is provided.
   * - All calls will be included if this is not provided.
   */
  fromToCombinationMode?: InputMaybe<CombinationMode>;
  /** Filter by call statuses. */
  statuses: Array<CallStatus>;
  /**
   * ID of the user assigned to the ongoing call.
   * - All concluded calls will be included regardless of the user if this is provided.
   * - All calls will be included if this is not provided.
   */
  thirdPartyId?: InputMaybe<Scalars['String']['input']>;
  /** Filter for the call's "to" field. */
  to?: InputMaybe<Scalars['String']['input']>;
};

/** A paginated response of calls with missedCount. */
export type CallsMissedPagination = {
  __typename?: 'CallsMissedPagination';
  /** Paginated call with `missedCount` items. */
  items: Array<CallWithMissedCount>;
  /** The total number of records to get from the indicated `offset`. */
  limit: Scalars['Float']['output'];
  /** The zero-based index indicator of where to start getting a record. */
  offset: Scalars['Float']['output'];
  /** Total number of records. Only available when `includeCount` is set to `true`. */
  totalCount: Scalars['Float']['output'];
};

/** A paginated response of calls. */
export type CallsPagination = {
  __typename?: 'CallsPagination';
  /** Paginated call items. */
  items: Array<Call>;
  /** The total number of records to get from the indicated `offset`. */
  limit: Scalars['Float']['output'];
  /** The zero-based index indicator of where to start getting a record. */
  offset: Scalars['Float']['output'];
  /** Total number of records. Only available when `includeCount` is set to `true`. */
  totalCount: Scalars['Float']['output'];
};

/** To enable UNION or INTERSECTION when fetching `from` and `to` fields. */
export enum CombinationMode {
  And = 'AND',
  Or = 'OR'
}

/** Concluded calls filter. */
export type ConcludedCallsFilterInput = {
  /** The phone number or contact name of the caller. */
  from?: InputMaybe<Scalars['String']['input']>;
  /** To phone number or contact name who called. */
  to?: InputMaybe<Scalars['String']['input']>;
};

/** Contact records used on the Phone Book */
export type Contact = {
  __typename?: 'Contact';
  /** Calls made by the contact. */
  callsMade: Array<Call>;
  /** Calls received by the contact. */
  callsReceived: Array<Call>;
  /** Datetime when the record was created. */
  createdDate: Scalars['DateTimeISO']['output'];
  /**
   * The raw name acquired from the Google people API.
   *
   * Format: `[<whatsapp_code>] parent_name/ student_full_name (academic_level academic_year), student_full_name (academic_level academic_year) ...`
   */
  displayName: Scalars['String']['output'];
  /** Email address acquired from the Google People API. */
  email?: Maybe<Scalars['String']['output']>;
  /** First name of the contact acquired from the Google People API. */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Last update timestamp from Google People API. (not to be confused with the `updatedDate` that is updated when an update operation on the DB is performed.) */
  googleUpdatedDate?: Maybe<Scalars['DateTimeISO']['output']>;
  /** Unique identifier of the Contact. */
  id: Scalars['String']['output'];
  /** List of issues found on the contact. */
  issues: Array<ContactIssue>;
  /** Last name of the contact acquired from the Google People API. */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Datetime when the record was last synced. */
  lastSyncedDate: Scalars['DateTimeISO']['output'];
  /** Middle name of the contact acquired from the Google People API. */
  middleName?: Maybe<Scalars['String']['output']>;
  /** Phone numbers acquired from the Google People API. */
  phoneNumbers: Array<ContactPhoneNumber>;
  /** URL to the photo of the contact. */
  photoUrl?: Maybe<Scalars['String']['output']>;
  /** Unique Id of the contact from the Google People API. */
  thirdPartyId: Scalars['String']['output'];
  /** Datetime when an update operation on the DB was performed. */
  updatedDate: Scalars['DateTimeISO']['output'];
};


/** Contact records used on the Phone Book */
export type ContactCallsMadeArgs = {
  cursor?: InputMaybe<CallWhereUniqueInput>;
  distinct?: InputMaybe<Array<CallScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CallOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CallWhereInput>;
};


/** Contact records used on the Phone Book */
export type ContactCallsReceivedArgs = {
  cursor?: InputMaybe<CallWhereUniqueInput>;
  distinct?: InputMaybe<Array<CallScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CallOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CallWhereInput>;
};

/** Input for getting a contact. */
export type ContactInput = {
  /** Id of contact. */
  id: Scalars['String']['input'];
};

/** Records the issue encountered upon syncing the contact from google contact. */
export type ContactIssue = {
  __typename?: 'ContactIssue';
  /** Message to display to the user. */
  message: Scalars['String']['output'];
  /** Name of the issue. */
  name: Scalars['String']['output'];
};

export type ContactIssueObjectEqualityInput = {
  message: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type ContactIssueOrderByCompositeAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ContactNullableRelationFilter = {
  is?: InputMaybe<ContactWhereInput>;
  isNot?: InputMaybe<ContactWhereInput>;
};

export type ContactOrderByWithRelationInput = {
  callsMade?: InputMaybe<CallOrderByRelationAggregateInput>;
  callsReceived?: InputMaybe<CallOrderByRelationAggregateInput>;
  createdDate?: InputMaybe<SortOrder>;
  displayName?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  googleUpdatedDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  issues?: InputMaybe<ContactIssueOrderByCompositeAggregateInput>;
  lastName?: InputMaybe<SortOrder>;
  lastSyncedDate?: InputMaybe<SortOrder>;
  middleName?: InputMaybe<SortOrder>;
  phoneNumbers?: InputMaybe<ContactPhoneNumberOrderByCompositeAggregateInput>;
  photoUrl?: InputMaybe<SortOrder>;
  thirdPartyId?: InputMaybe<SortOrder>;
  updatedDate?: InputMaybe<SortOrder>;
};

/** The schema for the phone number of the contact. */
export type ContactPhoneNumber = {
  __typename?: 'ContactPhoneNumber';
  /** Canonical form of the phone number. Eg.: `+15177434554` */
  canonicalForm?: Maybe<Scalars['String']['output']>;
  /** Country code of the phone number. Eg.: `1`, `65` */
  countryCode?: Maybe<Scalars['Int']['output']>;
  /** Label of the phone number acquired from the Google People API. */
  label?: Maybe<Scalars['String']['output']>;
  /** Phone number without the country code acquired from the Google People API. Eg.: `5177434554`, `12345678` */
  number?: Maybe<Scalars['String']['output']>;
  /** `true` if this is the primary number of the person. Each person should have at most one field with primary set to true. */
  primary: Scalars['Boolean']['output'];
  /** Raw form of the phone number. Eg.: `+1 517 743 4554`, `+93938191379`, `8961 7342`, etc. */
  rawForm: Scalars['String']['output'];
  /** This can be custom or one of these predefined values: home, work, mobile, homeFax, workFax, otherFax, pager, workMobile, workPager, main, googleVoice, other */
  type?: Maybe<PhoneNumberType>;
};

export type ContactPhoneNumberObjectEqualityInput = {
  canonicalForm?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['Int']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  primary: Scalars['Boolean']['input'];
  rawForm: Scalars['String']['input'];
  type?: InputMaybe<PhoneNumberType>;
};

export type ContactPhoneNumberOrderByCompositeAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

/** Contact search fields */
export enum ContactSearchField {
  DisplayName = 'DISPLAY_NAME',
  PhoneNumber = 'PHONE_NUMBER'
}

/** Input for syncing contacts. */
export type ContactSyncInput = {
  /** When specified, only the contacts that were updated after this date will be synced. */
  afterDate?: InputMaybe<Scalars['String']['input']>;
  /** Next sync token. Used to retrieve only the resources changed since the last request. */
  syncToken?: InputMaybe<Scalars['String']['input']>;
};

export type ContactWhereInput = {
  AND?: InputMaybe<Array<ContactWhereInput>>;
  NOT?: InputMaybe<Array<ContactWhereInput>>;
  OR?: InputMaybe<Array<ContactWhereInput>>;
  callsMade?: InputMaybe<CallListRelationFilter>;
  callsReceived?: InputMaybe<CallListRelationFilter>;
  createdDate?: InputMaybe<DateTimeFilter>;
  displayName?: InputMaybe<StringFilter>;
  email?: InputMaybe<StringNullableFilter>;
  firstName?: InputMaybe<StringNullableFilter>;
  googleUpdatedDate?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  issues?: InputMaybe<Array<ContactIssueObjectEqualityInput>>;
  lastName?: InputMaybe<StringNullableFilter>;
  lastSyncedDate?: InputMaybe<DateTimeFilter>;
  middleName?: InputMaybe<StringNullableFilter>;
  phoneNumbers?: InputMaybe<Array<ContactPhoneNumberObjectEqualityInput>>;
  photoUrl?: InputMaybe<StringNullableFilter>;
  thirdPartyId?: InputMaybe<StringFilter>;
  updatedDate?: InputMaybe<DateTimeFilter>;
};

/** Filter for getting contacts. */
export type ContactsFilterInput = {
  /** Fields to be included in the search. */
  includeFields?: InputMaybe<Array<ContactSearchField>>;
  /** Operation to be used in the search. Accepted values: 'AND', 'OR'. Defaults to 'OR'. */
  operation?: InputMaybe<WhereOperation>;
  /** Text to be searched. */
  searchText: Scalars['String']['input'];
};

/** A paginated response of calls. */
export type ContactsPagination = {
  __typename?: 'ContactsPagination';
  /** Paginated call items. */
  items: Array<Contact>;
  /** The total number of records to get from the indicated `offset`. */
  limit: Scalars['Float']['output'];
  /** The zero-based index indicator of where to start getting a record. */
  offset: Scalars['Float']['output'];
  /** Total number of records. Only available when `includeCount` is set to `true`. */
  totalCount: Scalars['Float']['output'];
};

/** Result of syncing contacts. */
export type ContactsSyncResponse = {
  __typename?: 'ContactsSyncResponse';
  /** Next sync token. Used to retrieve only the resources changed since the last request. */
  nextSyncToken?: Maybe<Scalars['String']['output']>;
  /** Number of contacts synced. */
  syncedCount: Scalars['Float']['output'];
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  isSet?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

/** Day of the week (e.g. MONDAY, TUESDAY, etc.). */
export enum DayOfWeek {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export type EnumCallDirectionFilter = {
  equals?: InputMaybe<CallDirection>;
  in?: InputMaybe<Array<CallDirection>>;
  not?: InputMaybe<NestedEnumCallDirectionFilter>;
  notIn?: InputMaybe<Array<CallDirection>>;
};

export type EnumCallEndedByNullableFilter = {
  equals?: InputMaybe<CallEndedBy>;
  in?: InputMaybe<Array<CallEndedBy>>;
  isSet?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedEnumCallEndedByNullableFilter>;
  notIn?: InputMaybe<Array<CallEndedBy>>;
};

export type EnumCallRecordingStatusFilter = {
  equals?: InputMaybe<CallRecordingStatus>;
  in?: InputMaybe<Array<CallRecordingStatus>>;
  not?: InputMaybe<NestedEnumCallRecordingStatusFilter>;
  notIn?: InputMaybe<Array<CallRecordingStatus>>;
};

export type EnumCallRoutingStatusFilter = {
  equals?: InputMaybe<CallRoutingStatus>;
  in?: InputMaybe<Array<CallRoutingStatus>>;
  not?: InputMaybe<NestedEnumCallRoutingStatusFilter>;
  notIn?: InputMaybe<Array<CallRoutingStatus>>;
};

export type EnumCallStatusFilter = {
  equals?: InputMaybe<CallStatus>;
  in?: InputMaybe<Array<CallStatus>>;
  not?: InputMaybe<NestedEnumCallStatusFilter>;
  notIn?: InputMaybe<Array<CallStatus>>;
};

export type EnumGenderNullableFilter = {
  equals?: InputMaybe<Gender>;
  in?: InputMaybe<Array<Gender>>;
  isSet?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedEnumGenderNullableFilter>;
  notIn?: InputMaybe<Array<Gender>>;
};

export type EnumSalutationNullableFilter = {
  equals?: InputMaybe<Salutation>;
  in?: InputMaybe<Array<Salutation>>;
  isSet?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedEnumSalutationNullableFilter>;
  notIn?: InputMaybe<Array<Salutation>>;
};

export type EnumTaskStatusFilter = {
  equals?: InputMaybe<TaskStatus>;
  in?: InputMaybe<Array<TaskStatus>>;
  not?: InputMaybe<NestedEnumTaskStatusFilter>;
  notIn?: InputMaybe<Array<TaskStatus>>;
};

export type EnumTaskTypeFilter = {
  equals?: InputMaybe<TaskType>;
  in?: InputMaybe<Array<TaskType>>;
  not?: InputMaybe<NestedEnumTaskTypeFilter>;
  notIn?: InputMaybe<Array<TaskType>>;
};

export type EnumUserAvailabilityStatusFilter = {
  equals?: InputMaybe<UserAvailabilityStatus>;
  in?: InputMaybe<Array<UserAvailabilityStatus>>;
  not?: InputMaybe<NestedEnumUserAvailabilityStatusFilter>;
  notIn?: InputMaybe<Array<UserAvailabilityStatus>>;
};

export type EnumUserTypeFilter = {
  equals?: InputMaybe<UserType>;
  in?: InputMaybe<Array<UserType>>;
  not?: InputMaybe<NestedEnumUserTypeFilter>;
  notIn?: InputMaybe<Array<UserType>>;
};

/** Gender. */
export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE'
}

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** Missed calls filter. */
export type MissedCallsFilterInput = {
  /** The phone number or contact name of the caller. */
  from?: InputMaybe<Scalars['String']['input']>;
  /** To phone number or contact name who called. */
  to?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Updates business hour settings for all 7 days of the week. */
  businessHoursUpdate?: Maybe<Array<BusinessHour>>;
  /** Accept a call. */
  callAccept: Call;
  /** Update the status of a call. */
  callAssign: Call;
  /** Cancel a ringing outbound call. */
  callCancel: Call;
  /** Updates the status of a call to "wrap. Only assigned agents can do this. Only calls in a "wrap up" status can be completed. */
  callComplete: Call;
  /** Creates a call in the database. This requires the "user:create" Auth0 permission. */
  callCreate: Call;
  /** Update the status of a call. */
  callMissedGroupAssign: CallMissedGroup;
  /** Update the status of a call. */
  callMissedGroupUnassign: CallMissedGroup;
  /** Reject a call. */
  callReject: Call;
  /** Update the call routing remark. */
  callRoutingUpdateRemark: CallRouting;
  /** Update the status of a call. */
  callUnassign: Call;
  /** Updates a call in the database. Returns the updated call. */
  callUpdate: Call;
  /** Update the summary field of a call. */
  callUpdateSummary: Call;
  /** Syncs the database with the Google People API. */
  contactsSync: ContactsSyncResponse;
  /** Updates a system preference. */
  systemPreferenceUpdate: SystemPreference;
  /** Creates a user in the database. This requires the "user:create" Auth0 permission. */
  userCreate: User;
  /** Updates a user in the database. Returns the updated user. This requires the "user:update" Auth0 permission. */
  userUpdate: User;
  /** Updates the user's availability status. */
  userUpdateAvailability: User;
  /** Deletes a user in the database. Returns the deleted user. This requires the "user:delete" Auth0 permission. */
  usersDelete: Array<User>;
};


export type MutationBusinessHoursUpdateArgs = {
  input: BusinessHoursUpdateInput;
};


export type MutationCallAcceptArgs = {
  input: CallAcceptInput;
};


export type MutationCallAssignArgs = {
  input: CallAssignInput;
};


export type MutationCallCancelArgs = {
  input: CallRejectInput;
};


export type MutationCallCompleteArgs = {
  input: CallCompleteInput;
};


export type MutationCallCreateArgs = {
  input: CallCreateInput;
};


export type MutationCallMissedGroupAssignArgs = {
  input: CallMissedGroupAssignInput;
};


export type MutationCallMissedGroupUnassignArgs = {
  input: CallMissedGroupUnassignInput;
};


export type MutationCallRejectArgs = {
  input: CallRejectInput;
};


export type MutationCallRoutingUpdateRemarkArgs = {
  input: CallRoutingUpdateRemarkInput;
};


export type MutationCallUnassignArgs = {
  input: CallUnassignInput;
};


export type MutationCallUpdateArgs = {
  input: CallUpdateInput;
};


export type MutationCallUpdateSummaryArgs = {
  input: CallUpdateSummaryInput;
};


export type MutationContactsSyncArgs = {
  input: ContactSyncInput;
};


export type MutationSystemPreferenceUpdateArgs = {
  input: SystemPreferenceUpdateInput;
};


export type MutationUserCreateArgs = {
  input: UserCreateInput;
};


export type MutationUserUpdateArgs = {
  input: UserUpdateInput;
};


export type MutationUserUpdateAvailabilityArgs = {
  input: UserUpdateAvailabilityInput;
};


export type MutationUsersDeleteArgs = {
  input: UsersDeleteInput;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  isSet?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type NestedEnumCallDirectionFilter = {
  equals?: InputMaybe<CallDirection>;
  in?: InputMaybe<Array<CallDirection>>;
  not?: InputMaybe<NestedEnumCallDirectionFilter>;
  notIn?: InputMaybe<Array<CallDirection>>;
};

export type NestedEnumCallEndedByNullableFilter = {
  equals?: InputMaybe<CallEndedBy>;
  in?: InputMaybe<Array<CallEndedBy>>;
  isSet?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedEnumCallEndedByNullableFilter>;
  notIn?: InputMaybe<Array<CallEndedBy>>;
};

export type NestedEnumCallRecordingStatusFilter = {
  equals?: InputMaybe<CallRecordingStatus>;
  in?: InputMaybe<Array<CallRecordingStatus>>;
  not?: InputMaybe<NestedEnumCallRecordingStatusFilter>;
  notIn?: InputMaybe<Array<CallRecordingStatus>>;
};

export type NestedEnumCallRoutingStatusFilter = {
  equals?: InputMaybe<CallRoutingStatus>;
  in?: InputMaybe<Array<CallRoutingStatus>>;
  not?: InputMaybe<NestedEnumCallRoutingStatusFilter>;
  notIn?: InputMaybe<Array<CallRoutingStatus>>;
};

export type NestedEnumCallStatusFilter = {
  equals?: InputMaybe<CallStatus>;
  in?: InputMaybe<Array<CallStatus>>;
  not?: InputMaybe<NestedEnumCallStatusFilter>;
  notIn?: InputMaybe<Array<CallStatus>>;
};

export type NestedEnumGenderNullableFilter = {
  equals?: InputMaybe<Gender>;
  in?: InputMaybe<Array<Gender>>;
  isSet?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedEnumGenderNullableFilter>;
  notIn?: InputMaybe<Array<Gender>>;
};

export type NestedEnumSalutationNullableFilter = {
  equals?: InputMaybe<Salutation>;
  in?: InputMaybe<Array<Salutation>>;
  isSet?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedEnumSalutationNullableFilter>;
  notIn?: InputMaybe<Array<Salutation>>;
};

export type NestedEnumTaskStatusFilter = {
  equals?: InputMaybe<TaskStatus>;
  in?: InputMaybe<Array<TaskStatus>>;
  not?: InputMaybe<NestedEnumTaskStatusFilter>;
  notIn?: InputMaybe<Array<TaskStatus>>;
};

export type NestedEnumTaskTypeFilter = {
  equals?: InputMaybe<TaskType>;
  in?: InputMaybe<Array<TaskType>>;
  not?: InputMaybe<NestedEnumTaskTypeFilter>;
  notIn?: InputMaybe<Array<TaskType>>;
};

export type NestedEnumUserAvailabilityStatusFilter = {
  equals?: InputMaybe<UserAvailabilityStatus>;
  in?: InputMaybe<Array<UserAvailabilityStatus>>;
  not?: InputMaybe<NestedEnumUserAvailabilityStatusFilter>;
  notIn?: InputMaybe<Array<UserAvailabilityStatus>>;
};

export type NestedEnumUserTypeFilter = {
  equals?: InputMaybe<UserType>;
  in?: InputMaybe<Array<UserType>>;
  not?: InputMaybe<NestedEnumUserTypeFilter>;
  notIn?: InputMaybe<Array<UserType>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  isSet?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

/** New business hour setting for a single day. */
export type NewBusinessHour = {
  /** When true, the logic should be based on startTime and endTime. When false, business hours are overriden and closed for that day. */
  active: Scalars['Boolean']['input'];
  /** Day of the week (Monday to Sunday). */
  day: DayOfWeek;
  /** End time of the business hours. (Date format that includes the time) */
  endTime: Scalars['DateTimeISO']['input'];
  /** Start time of the business hours. (Date format that includes the time) */
  startTime: Scalars['DateTimeISO']['input'];
};

/** DateTime range but start and end are optional. Useful when you want to filter by `start` to `latest` (empty end). Or `oldest` (empty start) to `end`. */
export type PartialDateTimeRangeInput = {
  /** End date in the range. When empty, assumed 'latest'. */
  end?: InputMaybe<Scalars['DateTimeISO']['input']>;
  /** Start date in the range. When empty, assumed 'oldest'. */
  start?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

/** Type of phone number. */
export enum PhoneNumberType {
  Landline = 'LANDLINE',
  Mobile = 'MOBILE'
}

export type Query = {
  __typename?: 'Query';
  /** Retrieve business hours configurations. */
  businessHours?: Maybe<Array<BusinessHour>>;
  /** Retrieve a single call. */
  call?: Maybe<Call>;
  /** Retrieve call records. Used for past interactions. */
  calls: Array<Call>;
  /** Retrieve concluded call records. */
  callsConcluded: CallsPagination;
  /** Retrieve missed call records. */
  callsMissed: CallsMissedPagination;
  /** Retrieve active call records viewable by the current logged on user. */
  callsUserActive: CallsPagination;
  /** Get a contact. */
  contact: Contact;
  /** Gets a list of contacts by filter. */
  contacts: ContactsPagination;
  /** Retrieve a system preference. */
  systemPreference?: Maybe<SystemPreference>;
  /** Retrieve the current user.. */
  userCurrent: User;
  /** Retrieves all the users in the database. Returns an empty array when there is no user in the system. This requires the "user:read" Auth0 permission. */
  users: Array<User>;
};


export type QueryCallArgs = {
  filter: CallFilterInput;
};


export type QueryCallsArgs = {
  filter?: InputMaybe<CallsFilterInput>;
};


export type QueryCallsConcludedArgs = {
  filter?: InputMaybe<ConcludedCallsFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCallsMissedArgs = {
  filter?: InputMaybe<MissedCallsFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCallsUserActiveArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryContactArgs = {
  input: ContactInput;
};


export type QueryContactsArgs = {
  filter?: InputMaybe<ContactsFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySystemPreferenceArgs = {
  filter: SystemPreferenceInput;
};


export type QueryUsersArgs = {
  filter?: InputMaybe<UsersFilterInput>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

/** User salutations. */
export enum Salutation {
  Mr = 'MR',
  Mrs = 'MRS',
  Ms = 'MS',
  PreferNotToSay = 'PREFER_NOT_TO_SAY'
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  isSet?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  /** Subscribe to business hour events. */
  businessHoursEvent: BusinessHourEvent;
  /** Subscribes to call events. */
  callEvent?: Maybe<Call>;
  /** Subscribe to call missed group add event. */
  callMissedGroupAddedEvent?: Maybe<CallWithMissedCount>;
  /** Subscribe to call missed group delete event. */
  callMissedGroupDeletedEvent?: Maybe<CallMissedGroup>;
  /** Subscribe to system preference events. */
  systemPreferenceEvent?: Maybe<SystemPreference>;
  /** Subscribes to the user events. */
  userEvent?: Maybe<User>;
};


export type SubscriptionCallEventArgs = {
  input: CallEventInput;
};


export type SubscriptionCallMissedGroupAddedEventArgs = {
  input: CallMissedGroupAddedEventInput;
};

/** System preferences used to store global settings. */
export type SystemPreference = {
  __typename?: 'SystemPreference';
  /** Description of the system preference. */
  description: Scalars['String']['output'];
  /** Unique identifier of the System Preference. */
  id: Scalars['String']['output'];
  /** Unique key of the system preference entry. There should be no spaces and special characters. Use underscores instead. */
  key: Scalars['String']['output'];
  /** Name of the system preference entry. */
  name: Scalars['String']['output'];
  /** Value of the system preference. Tracking the valid values of this is up to the developer. */
  value: Scalars['String']['output'];
};

/** Input for getting a system preference. */
export type SystemPreferenceInput = {
  /** Unique key of the system preference. */
  key: Scalars['String']['input'];
};

/** Input for updating a system preference record.. */
export type SystemPreferenceUpdateInput = {
  /** Description of the system preference. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Unique key of the system preference entry. There should be no spaces and special characters. Use underscores instead. */
  key?: InputMaybe<Scalars['String']['input']>;
  /** Name of the system preference entry. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Value of the system preference. */
  value?: InputMaybe<Scalars['String']['input']>;
};

/** A record which needs user action. */
export type Task = {
  __typename?: 'Task';
  /** Call associated to the task. */
  call: Call;
  callId: Scalars['String']['output'];
  callRouting: CallRouting;
  callRoutingId: Scalars['String']['output'];
  /** Date and time the task was created. */
  createdDate: Scalars['DateTimeISO']['output'];
  /** Description of the task. */
  description: Scalars['String']['output'];
  /** Unique identifier of the Task. */
  id: Scalars['String']['output'];
  /** Status of the task. */
  status: TaskStatus;
  /** Type of the task. */
  type: TaskType;
  /** Datetime when the task was updated. */
  updatedDate?: Maybe<Scalars['DateTimeISO']['output']>;
  /** User who is assigned to the task. */
  user: User;
  userId: Scalars['String']['output'];
};

export type TaskListRelationFilter = {
  every?: InputMaybe<TaskWhereInput>;
  none?: InputMaybe<TaskWhereInput>;
  some?: InputMaybe<TaskWhereInput>;
};

export type TaskOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TaskOrderByWithRelationInput = {
  call?: InputMaybe<CallOrderByWithRelationInput>;
  callId?: InputMaybe<SortOrder>;
  callRouting?: InputMaybe<CallRoutingOrderByWithRelationInput>;
  callRoutingId?: InputMaybe<SortOrder>;
  createdDate?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedDate?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum TaskScalarFieldEnum {
  CallId = 'callId',
  CallRoutingId = 'callRoutingId',
  CreatedDate = 'createdDate',
  Description = 'description',
  Id = 'id',
  Status = 'status',
  Type = 'type',
  UpdatedDate = 'updatedDate',
  UserId = 'userId'
}

/** Status of a task. */
export enum TaskStatus {
  Completed = 'COMPLETED',
  Created = 'CREATED',
  InProgress = 'IN_PROGRESS'
}

export enum TaskType {
  Call = 'CALL'
}

export type TaskWhereInput = {
  AND?: InputMaybe<Array<TaskWhereInput>>;
  NOT?: InputMaybe<Array<TaskWhereInput>>;
  OR?: InputMaybe<Array<TaskWhereInput>>;
  call?: InputMaybe<CallRelationFilter>;
  callId?: InputMaybe<StringFilter>;
  callRouting?: InputMaybe<CallRoutingRelationFilter>;
  callRoutingId?: InputMaybe<StringFilter>;
  createdDate?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumTaskStatusFilter>;
  type?: InputMaybe<EnumTaskTypeFilter>;
  updatedDate?: InputMaybe<DateTimeNullableFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type TaskWhereUniqueInput = {
  AND?: InputMaybe<Array<TaskWhereInput>>;
  NOT?: InputMaybe<Array<TaskWhereInput>>;
  OR?: InputMaybe<Array<TaskWhereInput>>;
  call?: InputMaybe<CallRelationFilter>;
  callId?: InputMaybe<StringFilter>;
  callRouting?: InputMaybe<CallRoutingRelationFilter>;
  callRoutingId?: InputMaybe<StringFilter>;
  createdDate?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EnumTaskStatusFilter>;
  type?: InputMaybe<EnumTaskTypeFilter>;
  updatedDate?: InputMaybe<DateTimeNullableFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

/** User record used to manage user access and permission. */
export type User = {
  __typename?: 'User';
  /** User's availability details. */
  availability: UserAvailability;
  /** Call routings related to the user. */
  callRoutings: CallRouting;
  /** The calls associated to the user. */
  calls: Array<Call>;
  /** Email address of the user. */
  email: Scalars['String']['output'];
  /** Unique identifier of the user. */
  id: Scalars['String']['output'];
  /** Personal details of the user. */
  profile: UserProfile;
  /** Tasks assigned to the user. */
  tasks: Task;
  /** Third-party ID of the user. e.g.: Auth0 User ID. */
  thirdPartyId: Scalars['String']['output'];
  /** Type of user. */
  type: UserType;
};


/** User record used to manage user access and permission. */
export type UserCallRoutingsArgs = {
  cursor?: InputMaybe<CallRoutingWhereUniqueInput>;
  distinct?: InputMaybe<Array<CallRoutingScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CallRoutingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CallRoutingWhereInput>;
};


/** User record used to manage user access and permission. */
export type UserCallsArgs = {
  cursor?: InputMaybe<CallWhereUniqueInput>;
  distinct?: InputMaybe<Array<CallScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CallOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CallWhereInput>;
};


/** User record used to manage user access and permission. */
export type UserTasksArgs = {
  cursor?: InputMaybe<TaskWhereUniqueInput>;
  distinct?: InputMaybe<Array<TaskScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TaskOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TaskWhereInput>;
};

export type UserAvailability = {
  __typename?: 'UserAvailability';
  /**
   * User availability status set by the user.
   * Use this to revert the status to the user's preference after ending a call.
   */
  defaultStatus: UserAvailabilityStatus;
  /** User availability status. This could be automatically set by the system. */
  status: UserAvailabilityStatus;
  /** Date and time the availability status was set. */
  updatedDate: Scalars['DateTimeISO']['output'];
};

export type UserAvailabilityCompositeFilter = {
  equals?: InputMaybe<UserAvailabilityObjectEqualityInput>;
  is?: InputMaybe<UserAvailabilityWhereInput>;
  isNot?: InputMaybe<UserAvailabilityWhereInput>;
};

export type UserAvailabilityObjectEqualityInput = {
  defaultStatus: UserAvailabilityStatus;
  status: UserAvailabilityStatus;
  updatedDate: Scalars['DateTimeISO']['input'];
};

export type UserAvailabilityOrderByInput = {
  defaultStatus?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedDate?: InputMaybe<SortOrder>;
};

/** User availability status. */
export enum UserAvailabilityStatus {
  Available = 'AVAILABLE',
  Break = 'BREAK',
  Busy = 'BUSY',
  Offline = 'OFFLINE',
  OnACall = 'ON_A_CALL',
  Ringing = 'RINGING',
  WrappingUp = 'WRAPPING_UP'
}

export type UserAvailabilityWhereInput = {
  AND?: InputMaybe<Array<UserAvailabilityWhereInput>>;
  NOT?: InputMaybe<Array<UserAvailabilityWhereInput>>;
  OR?: InputMaybe<Array<UserAvailabilityWhereInput>>;
  defaultStatus?: InputMaybe<EnumUserAvailabilityStatusFilter>;
  status?: InputMaybe<EnumUserAvailabilityStatusFilter>;
  updatedDate?: InputMaybe<DateTimeFilter>;
};

/** User availability input for creating a user. */
export type UserCreateAvailabilityInput = {
  /** Availability status of the user. */
  status: UserAvailabilityStatus;
};

/** Input for creating a user. */
export type UserCreateInput = {
  /** Availability of the user. */
  availability?: InputMaybe<UserCreateAvailabilityInput>;
  /** Email of the user. This will also serve as the user's username. */
  email: Scalars['String']['input'];
  /** Profile of the user. */
  profile: UserCreateProfileInput;
  /** Third--party ID of the user. This could bhe the ID of the user from the identity provider. */
  thirdPartyId: Scalars['String']['input'];
  /** Type of the user. */
  type: UserType;
};

/** Profile input for creating a user. */
export type UserCreateProfileInput = {
  /** Full name of the user. */
  fullName: Scalars['String']['input'];
  /** Gender of the user. */
  gender: Gender;
  /** Initials of the user. */
  initials?: InputMaybe<Scalars['String']['input']>;
  /** Nickname of the user. */
  nickname?: InputMaybe<Scalars['String']['input']>;
  /** Salutation of the user. */
  salutation?: InputMaybe<Salutation>;
};

export type UserNullableRelationFilter = {
  is?: InputMaybe<UserWhereInput>;
  isNot?: InputMaybe<UserWhereInput>;
};

export type UserOrderByWithRelationInput = {
  availability?: InputMaybe<UserAvailabilityOrderByInput>;
  callRoutings?: InputMaybe<CallRoutingOrderByRelationAggregateInput>;
  calls?: InputMaybe<CallOrderByRelationAggregateInput>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  profile?: InputMaybe<UserProfileOrderByInput>;
  tasks?: InputMaybe<TaskOrderByRelationAggregateInput>;
  thirdPartyId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

/** Personal information of the user. */
export type UserProfile = {
  __typename?: 'UserProfile';
  /** Full name of the user. */
  fullName: Scalars['String']['output'];
  /** Gender of the user. */
  gender?: Maybe<Gender>;
  /** Initials of the user. This will be auto generated. */
  initials?: Maybe<Scalars['String']['output']>;
  /** Nickname of the user. */
  nickname?: Maybe<Scalars['String']['output']>;
  /** Salutation of the user. */
  salutation?: Maybe<Salutation>;
};

export type UserProfileCompositeFilter = {
  equals?: InputMaybe<UserProfileObjectEqualityInput>;
  is?: InputMaybe<UserProfileWhereInput>;
  isNot?: InputMaybe<UserProfileWhereInput>;
};

export type UserProfileObjectEqualityInput = {
  fullName: Scalars['String']['input'];
  gender?: InputMaybe<Gender>;
  initials?: InputMaybe<Scalars['String']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  salutation?: InputMaybe<Salutation>;
};

export type UserProfileOrderByInput = {
  fullName?: InputMaybe<SortOrder>;
  gender?: InputMaybe<SortOrder>;
  initials?: InputMaybe<SortOrder>;
  nickname?: InputMaybe<SortOrder>;
  salutation?: InputMaybe<SortOrder>;
};

export type UserProfileWhereInput = {
  AND?: InputMaybe<Array<UserProfileWhereInput>>;
  NOT?: InputMaybe<Array<UserProfileWhereInput>>;
  OR?: InputMaybe<Array<UserProfileWhereInput>>;
  fullName?: InputMaybe<StringFilter>;
  gender?: InputMaybe<EnumGenderNullableFilter>;
  initials?: InputMaybe<StringNullableFilter>;
  nickname?: InputMaybe<StringNullableFilter>;
  salutation?: InputMaybe<EnumSalutationNullableFilter>;
};

export type UserRelationFilter = {
  is?: InputMaybe<UserWhereInput>;
  isNot?: InputMaybe<UserWhereInput>;
};

/** Types of  a user. */
export enum UserType {
  Default = 'DEFAULT',
  Owner = 'OWNER'
}

/** Update user availability input. */
export type UserUpdateAvailabilityInput = {
  /** User availability status. */
  availabilityStatus: UserAvailabilityStatus;
};

/** Input for updating a user. */
export type UserUpdateInput = {
  /** Email of the user. This will also serve as the user's username. */
  email?: InputMaybe<Scalars['String']['input']>;
  /** ID of the user. */
  id: Scalars['String']['input'];
  /** Profile of the user. */
  profile: UserUpdateProfileInput;
  /** Third--party ID of the user. This could bhe the ID of the user from the identity provider. */
  thirdPartyId: Scalars['String']['input'];
  /** Type of the user. */
  type?: InputMaybe<UserType>;
};

/** Profile input for updating a user. */
export type UserUpdateProfileInput = {
  /** Full name of the user. */
  fullName: Scalars['String']['input'];
  /** Gender of the user. */
  gender: Gender;
  /** Initials of the user. */
  initials?: InputMaybe<Scalars['String']['input']>;
  /** Nickname of the user. */
  nickname?: InputMaybe<Scalars['String']['input']>;
  /** Salutation of the user. */
  salutation?: InputMaybe<Salutation>;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  availability?: InputMaybe<UserAvailabilityCompositeFilter>;
  callRoutings?: InputMaybe<CallRoutingListRelationFilter>;
  calls?: InputMaybe<CallListRelationFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  profile?: InputMaybe<UserProfileCompositeFilter>;
  tasks?: InputMaybe<TaskListRelationFilter>;
  thirdPartyId?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumUserTypeFilter>;
};

/** Input for deleting one or more users. */
export type UsersDeleteInput = {
  /** IDs of the user. */
  ids: Array<Scalars['String']['input']>;
};

/** Filters for retrieving users. */
export type UsersFilterInput = {
  /** Email address of the user. */
  emails?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Arrays of user IDs of the user. */
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Full or partial name of the user. */
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Operation to be used in the search. */
export enum WhereOperation {
  And = 'AND',
  Or = 'OR'
}

export type BusinessHoursUpdateMutationVariables = Exact<{
  input: BusinessHoursUpdateInput;
}>;


export type BusinessHoursUpdateMutation = { __typename?: 'Mutation', businessHoursUpdate?: Array<{ __typename?: 'BusinessHour', id: string, day: DayOfWeek, startTime: any, endTime: any, active: boolean }> | null };

export type BusinessHoursQueryVariables = Exact<{ [key: string]: never; }>;


export type BusinessHoursQuery = { __typename?: 'Query', businessHours?: Array<{ __typename?: 'BusinessHour', id: string, day: DayOfWeek, startTime: any, endTime: any, active: boolean }> | null };

export type BusinessHoursEventSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type BusinessHoursEventSubscription = { __typename?: 'Subscription', businessHoursEvent: { __typename?: 'BusinessHourEvent', id: string, type: string, payload: { __typename?: 'BusinessHourEventPayload', businessHours: Array<{ __typename?: 'BusinessHour', id: string, day: DayOfWeek, startTime: any, endTime: any, active: boolean }> } } };

export type CallMissedGroupAddedEventSubscriptionVariables = Exact<{
  input: CallMissedGroupAddedEventInput;
}>;


export type CallMissedGroupAddedEventSubscription = { __typename?: 'Subscription', callMissedGroupAddedEvent?: { __typename?: 'CallWithMissedCount', id: string, callSid: string, date: any, dateEnded?: any | null, dateStarted?: any | null, direction: CallDirection, duration: number, from: string, status: CallStatus, summary?: string | null, to: string, userId?: string | null, missedCount: number, fromContact?: { __typename?: 'Contact', id: string, thirdPartyId: string, displayName: string, email?: string | null, googleUpdatedDate?: any | null, photoUrl?: string | null, lastSyncedDate: any, createdDate: any, updatedDate: any, phoneNumbers: Array<{ __typename?: 'ContactPhoneNumber', rawForm: string, canonicalForm?: string | null, countryCode?: number | null, number?: string | null, primary: boolean, type?: PhoneNumberType | null, label?: string | null }>, issues: Array<{ __typename?: 'ContactIssue', name: string, message: string }> } | null, toContact?: { __typename?: 'Contact', id: string, thirdPartyId: string, displayName: string, email?: string | null, googleUpdatedDate?: any | null, photoUrl?: string | null, lastSyncedDate: any, createdDate: any, updatedDate: any, phoneNumbers: Array<{ __typename?: 'ContactPhoneNumber', rawForm: string, canonicalForm?: string | null, countryCode?: number | null, number?: string | null, primary: boolean, type?: PhoneNumberType | null, label?: string | null }>, issues: Array<{ __typename?: 'ContactIssue', name: string, message: string }> } | null, user?: { __typename?: 'User', id: string, thirdPartyId: string, profile: { __typename?: 'UserProfile', fullName: string } } | null } | null };

export type CallMissedGroupDeletedEventSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type CallMissedGroupDeletedEventSubscription = { __typename?: 'Subscription', callMissedGroupDeletedEvent?: { __typename?: 'CallMissedGroup', id: string, lastCallId: string, lastMissedDate: any, phoneNumber: string } | null };

export type CallRoutingUpdateRemarkMutationVariables = Exact<{
  input: CallRoutingUpdateRemarkInput;
}>;


export type CallRoutingUpdateRemarkMutation = { __typename?: 'Mutation', callRoutingUpdateRemark: { __typename?: 'CallRouting', id: string, callId: string, date: any, remark?: string | null, status: CallRoutingStatus, userId: string } };

export type CallAcceptMutationVariables = Exact<{
  input: CallAcceptInput;
}>;


export type CallAcceptMutation = { __typename?: 'Mutation', callAccept: { __typename?: 'Call', id: string, callSid: string, date: any, direction: CallDirection, from: string, status: CallStatus, to: string, userId?: string | null } };

export type CallCompleteMutationVariables = Exact<{
  input: CallCompleteInput;
}>;


export type CallCompleteMutation = { __typename?: 'Mutation', callComplete: { __typename?: 'Call', id: string, callSid: string, date: any, dateStarted?: any | null, dateEnded?: any | null, direction: CallDirection, duration: number, from: string, status: CallStatus, endedBy?: CallEndedBy | null, to: string, userId?: string | null } };

export type CallCreateMutationVariables = Exact<{
  input: CallCreateInput;
}>;


export type CallCreateMutation = { __typename?: 'Mutation', callCreate: { __typename?: 'Call', id: string, callSid: string, date: any, dateStarted?: any | null, dateEnded?: any | null, direction: CallDirection, duration: number, from: string, status: CallStatus, endedBy?: CallEndedBy | null, to: string, userId?: string | null } };

export type CallCancelMutationVariables = Exact<{
  input: CallRejectInput;
}>;


export type CallCancelMutation = { __typename?: 'Mutation', callCancel: { __typename?: 'Call', id: string, callSid: string, date: any, dateStarted?: any | null, dateEnded?: any | null, direction: CallDirection, duration: number, from: string, status: CallStatus, endedBy?: CallEndedBy | null, to: string, userId?: string | null } };

export type CallRejectMutationVariables = Exact<{
  input: CallRejectInput;
}>;


export type CallRejectMutation = { __typename?: 'Mutation', callReject: { __typename?: 'Call', id: string, callSid: string, date: any, direction: CallDirection, from: string, status: CallStatus, to: string, userId?: string | null, routings: Array<{ __typename?: 'CallRouting', id: string }> } };

export type CallUpdateSummaryMutationVariables = Exact<{
  input: CallUpdateSummaryInput;
}>;


export type CallUpdateSummaryMutation = { __typename?: 'Mutation', callUpdateSummary: { __typename?: 'Call', id: string, callSid: string, date: any, dateStarted?: any | null, dateEnded?: any | null, direction: CallDirection, duration: number, from: string, summary?: string | null, status: CallStatus, endedBy?: CallEndedBy | null, to: string, userId?: string | null } };

export type CallAssignMutationVariables = Exact<{
  input: CallAssignInput;
}>;


export type CallAssignMutation = { __typename?: 'Mutation', callAssign: { __typename?: 'Call', id: string } };

export type CallUnassignMutationVariables = Exact<{
  input: CallUnassignInput;
}>;


export type CallUnassignMutation = { __typename?: 'Mutation', callUnassign: { __typename?: 'Call', id: string } };

export type CallMissedGroupAssignMutationVariables = Exact<{
  input: CallMissedGroupAssignInput;
}>;


export type CallMissedGroupAssignMutation = { __typename?: 'Mutation', callMissedGroupAssign: { __typename?: 'CallMissedGroup', id: string } };

export type CallMissedGroupUnassignMutationVariables = Exact<{
  input: CallMissedGroupUnassignInput;
}>;


export type CallMissedGroupUnassignMutation = { __typename?: 'Mutation', callMissedGroupUnassign: { __typename?: 'CallMissedGroup', id: string } };

export type CallsUserActiveQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type CallsUserActiveQuery = { __typename?: 'Query', callsUserActive: { __typename?: 'CallsPagination', limit: number, offset: number, totalCount: number, items: Array<{ __typename?: 'Call', id: string, callSid: string, date: any, dateEnded?: any | null, dateStarted?: any | null, direction: CallDirection, duration: number, from: string, status: CallStatus, summary?: string | null, to: string, userId?: string | null, fromContact?: { __typename?: 'Contact', id: string, thirdPartyId: string, displayName: string, email?: string | null, googleUpdatedDate?: any | null, photoUrl?: string | null, lastSyncedDate: any, createdDate: any, updatedDate: any, phoneNumbers: Array<{ __typename?: 'ContactPhoneNumber', rawForm: string, canonicalForm?: string | null, countryCode?: number | null, number?: string | null, primary: boolean, type?: PhoneNumberType | null, label?: string | null }>, issues: Array<{ __typename?: 'ContactIssue', name: string, message: string }> } | null, toContact?: { __typename?: 'Contact', id: string, thirdPartyId: string, displayName: string, email?: string | null, googleUpdatedDate?: any | null, photoUrl?: string | null, lastSyncedDate: any, createdDate: any, updatedDate: any, phoneNumbers: Array<{ __typename?: 'ContactPhoneNumber', rawForm: string, canonicalForm?: string | null, countryCode?: number | null, number?: string | null, primary: boolean, type?: PhoneNumberType | null, label?: string | null }>, issues: Array<{ __typename?: 'ContactIssue', name: string, message: string }> } | null, user?: { __typename?: 'User', id: string, thirdPartyId: string, profile: { __typename?: 'UserProfile', fullName: string } } | null }> } };

export type CallsConcludedQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<ConcludedCallsFilterInput>;
}>;


export type CallsConcludedQuery = { __typename?: 'Query', callsConcluded: { __typename?: 'CallsPagination', limit: number, offset: number, totalCount: number, items: Array<{ __typename?: 'Call', id: string, callSid: string, date: any, dateEnded?: any | null, dateStarted?: any | null, direction: CallDirection, duration: number, from: string, status: CallStatus, summary?: string | null, to: string, userId?: string | null, fromContact?: { __typename?: 'Contact', id: string, thirdPartyId: string, displayName: string, email?: string | null, googleUpdatedDate?: any | null, photoUrl?: string | null, lastSyncedDate: any, createdDate: any, updatedDate: any, phoneNumbers: Array<{ __typename?: 'ContactPhoneNumber', rawForm: string, canonicalForm?: string | null, countryCode?: number | null, number?: string | null, primary: boolean, type?: PhoneNumberType | null, label?: string | null }>, issues: Array<{ __typename?: 'ContactIssue', name: string, message: string }> } | null, toContact?: { __typename?: 'Contact', id: string, thirdPartyId: string, displayName: string, email?: string | null, googleUpdatedDate?: any | null, photoUrl?: string | null, lastSyncedDate: any, createdDate: any, updatedDate: any, phoneNumbers: Array<{ __typename?: 'ContactPhoneNumber', rawForm: string, canonicalForm?: string | null, countryCode?: number | null, number?: string | null, primary: boolean, type?: PhoneNumberType | null, label?: string | null }>, issues: Array<{ __typename?: 'ContactIssue', name: string, message: string }> } | null, user?: { __typename?: 'User', id: string, thirdPartyId: string, profile: { __typename?: 'UserProfile', fullName: string } } | null }> } };

export type CallsMissedQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<MissedCallsFilterInput>;
}>;


export type CallsMissedQuery = { __typename?: 'Query', callsMissed: { __typename?: 'CallsMissedPagination', limit: number, offset: number, totalCount: number, items: Array<{ __typename?: 'CallWithMissedCount', id: string, callSid: string, date: any, dateEnded?: any | null, dateStarted?: any | null, direction: CallDirection, duration: number, from: string, status: CallStatus, summary?: string | null, to: string, userId?: string | null, missedCount: number, fromContact?: { __typename?: 'Contact', id: string, thirdPartyId: string, displayName: string, email?: string | null, googleUpdatedDate?: any | null, photoUrl?: string | null, lastSyncedDate: any, createdDate: any, updatedDate: any, phoneNumbers: Array<{ __typename?: 'ContactPhoneNumber', rawForm: string, canonicalForm?: string | null, countryCode?: number | null, number?: string | null, primary: boolean, type?: PhoneNumberType | null, label?: string | null }>, issues: Array<{ __typename?: 'ContactIssue', name: string, message: string }> } | null, toContact?: { __typename?: 'Contact', id: string, thirdPartyId: string, displayName: string, email?: string | null, googleUpdatedDate?: any | null, photoUrl?: string | null, lastSyncedDate: any, createdDate: any, updatedDate: any, phoneNumbers: Array<{ __typename?: 'ContactPhoneNumber', rawForm: string, canonicalForm?: string | null, countryCode?: number | null, number?: string | null, primary: boolean, type?: PhoneNumberType | null, label?: string | null }>, issues: Array<{ __typename?: 'ContactIssue', name: string, message: string }> } | null, user?: { __typename?: 'User', id: string, thirdPartyId: string, profile: { __typename?: 'UserProfile', fullName: string } } | null }> } };

export type CallQueryVariables = Exact<{
  filter: CallFilterInput;
}>;


export type CallQuery = { __typename?: 'Query', call?: { __typename?: 'Call', id: string, callSid: string, date: any, dateEnded?: any | null, dateStarted?: any | null, direction: CallDirection, duration: number, from: string, status: CallStatus, summary?: string | null, to: string, userId?: string | null, fromContact?: { __typename?: 'Contact', id: string, thirdPartyId: string, displayName: string, email?: string | null, googleUpdatedDate?: any | null, photoUrl?: string | null, lastSyncedDate: any, createdDate: any, updatedDate: any, phoneNumbers: Array<{ __typename?: 'ContactPhoneNumber', rawForm: string, canonicalForm?: string | null, countryCode?: number | null, number?: string | null, primary: boolean, type?: PhoneNumberType | null, label?: string | null }>, issues: Array<{ __typename?: 'ContactIssue', name: string, message: string }> } | null, toContact?: { __typename?: 'Contact', id: string, thirdPartyId: string, displayName: string, email?: string | null, googleUpdatedDate?: any | null, photoUrl?: string | null, lastSyncedDate: any, createdDate: any, updatedDate: any, phoneNumbers: Array<{ __typename?: 'ContactPhoneNumber', rawForm: string, canonicalForm?: string | null, countryCode?: number | null, number?: string | null, primary: boolean, type?: PhoneNumberType | null, label?: string | null }>, issues: Array<{ __typename?: 'ContactIssue', name: string, message: string }> } | null, user?: { __typename?: 'User', id: string, thirdPartyId: string, profile: { __typename?: 'UserProfile', fullName: string } } | null, routings: Array<{ __typename?: 'CallRouting', id: string, date: any, remark?: string | null, status: CallRoutingStatus, user: { __typename?: 'User', id: string, thirdPartyId: string, profile: { __typename?: 'UserProfile', fullName: string } } }>, recording?: { __typename?: 'CallRecording', recordingSid: string, recordingUrl?: string | null, synced: boolean, thirdPartyUrl: string, status: CallRecordingStatus } | null } | null };

export type PastInteractionsQueryVariables = Exact<{
  clientPhoneNumber?: InputMaybe<Scalars['String']['input']>;
}>;


export type PastInteractionsQuery = { __typename?: 'Query', calls: Array<{ __typename?: 'Call', id: string, callSid: string, date: any, dateStarted?: any | null, direction: CallDirection, status: CallStatus, summary?: string | null, user?: { __typename?: 'User', id: string, profile: { __typename?: 'UserProfile', fullName: string } } | null }> };

export type CallEventSubscriptionVariables = Exact<{
  input: CallEventInput;
}>;


export type CallEventSubscription = { __typename?: 'Subscription', callEvent?: { __typename?: 'Call', id: string, callSid: string, date: any, dateEnded?: any | null, dateStarted?: any | null, direction: CallDirection, duration: number, from: string, status: CallStatus, summary?: string | null, to: string, userId?: string | null, fromContact?: { __typename?: 'Contact', id: string, thirdPartyId: string, displayName: string, email?: string | null, googleUpdatedDate?: any | null, photoUrl?: string | null, lastSyncedDate: any, createdDate: any, updatedDate: any, phoneNumbers: Array<{ __typename?: 'ContactPhoneNumber', rawForm: string, canonicalForm?: string | null, countryCode?: number | null, number?: string | null, primary: boolean, type?: PhoneNumberType | null, label?: string | null }>, issues: Array<{ __typename?: 'ContactIssue', name: string, message: string }> } | null, toContact?: { __typename?: 'Contact', id: string, thirdPartyId: string, displayName: string, email?: string | null, googleUpdatedDate?: any | null, photoUrl?: string | null, lastSyncedDate: any, createdDate: any, updatedDate: any, phoneNumbers: Array<{ __typename?: 'ContactPhoneNumber', rawForm: string, canonicalForm?: string | null, countryCode?: number | null, number?: string | null, primary: boolean, type?: PhoneNumberType | null, label?: string | null }>, issues: Array<{ __typename?: 'ContactIssue', name: string, message: string }> } | null, user?: { __typename?: 'User', id: string, thirdPartyId: string, profile: { __typename?: 'UserProfile', fullName: string } } | null } | null };

export type ContactSyncMutationVariables = Exact<{
  input: ContactSyncInput;
}>;


export type ContactSyncMutation = { __typename?: 'Mutation', contactsSync: { __typename?: 'ContactsSyncResponse', syncedCount: number, nextSyncToken?: string | null } };

export type ContactsQueryVariables = Exact<{
  filter: ContactsFilterInput;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ContactsQuery = { __typename?: 'Query', contacts: { __typename?: 'ContactsPagination', totalCount: number, limit: number, offset: number, items: Array<{ __typename?: 'Contact', id: string, thirdPartyId: string, displayName: string, email?: string | null, googleUpdatedDate?: any | null, photoUrl?: string | null, lastSyncedDate: any, createdDate: any, updatedDate: any, phoneNumbers: Array<{ __typename?: 'ContactPhoneNumber', rawForm: string, canonicalForm?: string | null, countryCode?: number | null, number?: string | null, primary: boolean, type?: PhoneNumberType | null, label?: string | null }>, issues: Array<{ __typename?: 'ContactIssue', name: string, message: string }> }> } };

export type SystemPreferenceUpdateMutationVariables = Exact<{
  input: SystemPreferenceUpdateInput;
}>;


export type SystemPreferenceUpdateMutation = { __typename?: 'Mutation', systemPreferenceUpdate: { __typename?: 'SystemPreference', id: string, description: string, key: string, name: string, value: string } };

export type SystemPreferenceQueryVariables = Exact<{
  filter: SystemPreferenceInput;
}>;


export type SystemPreferenceQuery = { __typename?: 'Query', systemPreference?: { __typename?: 'SystemPreference', id: string, description: string, key: string, name: string, value: string } | null };

export type SystemPreferenceEventSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type SystemPreferenceEventSubscription = { __typename?: 'Subscription', systemPreferenceEvent?: { __typename?: 'SystemPreference', id: string, description: string, key: string, name: string, value: string } | null };

export type UserUpdateAvailabilityMutationVariables = Exact<{
  input: UserUpdateAvailabilityInput;
}>;


export type UserUpdateAvailabilityMutation = { __typename?: 'Mutation', userUpdateAvailability: { __typename?: 'User', availability: { __typename?: 'UserAvailability', status: UserAvailabilityStatus, updatedDate: any } } };

export type UsersQueryVariables = Exact<{
  filter?: InputMaybe<UsersFilterInput>;
}>;


export type UsersQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', email: string, id: string, availability: { __typename?: 'UserAvailability', status: UserAvailabilityStatus, updatedDate: any }, profile: { __typename?: 'UserProfile', fullName: string } }> };

export type UserEventSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type UserEventSubscription = { __typename?: 'Subscription', userEvent?: { __typename?: 'User', email: string, id: string, availability: { __typename?: 'UserAvailability', status: UserAvailabilityStatus, updatedDate: any }, profile: { __typename?: 'UserProfile', fullName: string } } | null };


export const BusinessHoursUpdateDocument = gql`
    mutation BusinessHoursUpdate($input: BusinessHoursUpdateInput!) {
  businessHoursUpdate(input: $input) {
    id
    day
    startTime
    endTime
    active
  }
}
    `;
export type BusinessHoursUpdateMutationFn = Apollo.MutationFunction<BusinessHoursUpdateMutation, BusinessHoursUpdateMutationVariables>;

/**
 * __useBusinessHoursUpdateMutation__
 *
 * To run a mutation, you first call `useBusinessHoursUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBusinessHoursUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [businessHoursUpdateMutation, { data, loading, error }] = useBusinessHoursUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBusinessHoursUpdateMutation(baseOptions?: Apollo.MutationHookOptions<BusinessHoursUpdateMutation, BusinessHoursUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BusinessHoursUpdateMutation, BusinessHoursUpdateMutationVariables>(BusinessHoursUpdateDocument, options);
      }
export type BusinessHoursUpdateMutationHookResult = ReturnType<typeof useBusinessHoursUpdateMutation>;
export type BusinessHoursUpdateMutationResult = Apollo.MutationResult<BusinessHoursUpdateMutation>;
export type BusinessHoursUpdateMutationOptions = Apollo.BaseMutationOptions<BusinessHoursUpdateMutation, BusinessHoursUpdateMutationVariables>;
export const BusinessHoursDocument = gql`
    query BusinessHours {
  businessHours {
    id
    day
    startTime
    endTime
    active
  }
}
    `;

/**
 * __useBusinessHoursQuery__
 *
 * To run a query within a React component, call `useBusinessHoursQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessHoursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessHoursQuery({
 *   variables: {
 *   },
 * });
 */
export function useBusinessHoursQuery(baseOptions?: Apollo.QueryHookOptions<BusinessHoursQuery, BusinessHoursQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessHoursQuery, BusinessHoursQueryVariables>(BusinessHoursDocument, options);
      }
export function useBusinessHoursLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessHoursQuery, BusinessHoursQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessHoursQuery, BusinessHoursQueryVariables>(BusinessHoursDocument, options);
        }
export function useBusinessHoursSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BusinessHoursQuery, BusinessHoursQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BusinessHoursQuery, BusinessHoursQueryVariables>(BusinessHoursDocument, options);
        }
export type BusinessHoursQueryHookResult = ReturnType<typeof useBusinessHoursQuery>;
export type BusinessHoursLazyQueryHookResult = ReturnType<typeof useBusinessHoursLazyQuery>;
export type BusinessHoursSuspenseQueryHookResult = ReturnType<typeof useBusinessHoursSuspenseQuery>;
export type BusinessHoursQueryResult = Apollo.QueryResult<BusinessHoursQuery, BusinessHoursQueryVariables>;
export const BusinessHoursEventDocument = gql`
    subscription BusinessHoursEvent {
  businessHoursEvent {
    id
    type
    payload {
      businessHours {
        id
        day
        startTime
        endTime
        active
      }
    }
  }
}
    `;

/**
 * __useBusinessHoursEventSubscription__
 *
 * To run a query within a React component, call `useBusinessHoursEventSubscription` and pass it any options that fit your needs.
 * When your component renders, `useBusinessHoursEventSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessHoursEventSubscription({
 *   variables: {
 *   },
 * });
 */
export function useBusinessHoursEventSubscription(baseOptions?: Apollo.SubscriptionHookOptions<BusinessHoursEventSubscription, BusinessHoursEventSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<BusinessHoursEventSubscription, BusinessHoursEventSubscriptionVariables>(BusinessHoursEventDocument, options);
      }
export type BusinessHoursEventSubscriptionHookResult = ReturnType<typeof useBusinessHoursEventSubscription>;
export type BusinessHoursEventSubscriptionResult = Apollo.SubscriptionResult<BusinessHoursEventSubscription>;
export const CallMissedGroupAddedEventDocument = gql`
    subscription CallMissedGroupAddedEvent($input: CallMissedGroupAddedEventInput!) {
  callMissedGroupAddedEvent(input: $input) {
    id
    callSid
    date
    dateEnded
    dateStarted
    direction
    duration
    from
    fromContact {
      id
      thirdPartyId
      displayName
      email
      phoneNumbers {
        rawForm
        canonicalForm
        countryCode
        number
        primary
        type
        label
      }
      issues {
        name
        message
      }
      googleUpdatedDate
      photoUrl
      lastSyncedDate
      createdDate
      updatedDate
    }
    status
    summary
    to
    toContact {
      id
      thirdPartyId
      displayName
      email
      phoneNumbers {
        rawForm
        canonicalForm
        countryCode
        number
        primary
        type
        label
      }
      issues {
        name
        message
      }
      googleUpdatedDate
      photoUrl
      lastSyncedDate
      createdDate
      updatedDate
    }
    userId
    user {
      id
      profile {
        fullName
      }
      thirdPartyId
    }
    missedCount
  }
}
    `;

/**
 * __useCallMissedGroupAddedEventSubscription__
 *
 * To run a query within a React component, call `useCallMissedGroupAddedEventSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCallMissedGroupAddedEventSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallMissedGroupAddedEventSubscription({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCallMissedGroupAddedEventSubscription(baseOptions: Apollo.SubscriptionHookOptions<CallMissedGroupAddedEventSubscription, CallMissedGroupAddedEventSubscriptionVariables> & ({ variables: CallMissedGroupAddedEventSubscriptionVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<CallMissedGroupAddedEventSubscription, CallMissedGroupAddedEventSubscriptionVariables>(CallMissedGroupAddedEventDocument, options);
      }
export type CallMissedGroupAddedEventSubscriptionHookResult = ReturnType<typeof useCallMissedGroupAddedEventSubscription>;
export type CallMissedGroupAddedEventSubscriptionResult = Apollo.SubscriptionResult<CallMissedGroupAddedEventSubscription>;
export const CallMissedGroupDeletedEventDocument = gql`
    subscription CallMissedGroupDeletedEvent {
  callMissedGroupDeletedEvent {
    id
    lastCallId
    lastMissedDate
    phoneNumber
  }
}
    `;

/**
 * __useCallMissedGroupDeletedEventSubscription__
 *
 * To run a query within a React component, call `useCallMissedGroupDeletedEventSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCallMissedGroupDeletedEventSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallMissedGroupDeletedEventSubscription({
 *   variables: {
 *   },
 * });
 */
export function useCallMissedGroupDeletedEventSubscription(baseOptions?: Apollo.SubscriptionHookOptions<CallMissedGroupDeletedEventSubscription, CallMissedGroupDeletedEventSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<CallMissedGroupDeletedEventSubscription, CallMissedGroupDeletedEventSubscriptionVariables>(CallMissedGroupDeletedEventDocument, options);
      }
export type CallMissedGroupDeletedEventSubscriptionHookResult = ReturnType<typeof useCallMissedGroupDeletedEventSubscription>;
export type CallMissedGroupDeletedEventSubscriptionResult = Apollo.SubscriptionResult<CallMissedGroupDeletedEventSubscription>;
export const CallRoutingUpdateRemarkDocument = gql`
    mutation CallRoutingUpdateRemark($input: CallRoutingUpdateRemarkInput!) {
  callRoutingUpdateRemark(input: $input) {
    id
    callId
    date
    remark
    status
    userId
  }
}
    `;
export type CallRoutingUpdateRemarkMutationFn = Apollo.MutationFunction<CallRoutingUpdateRemarkMutation, CallRoutingUpdateRemarkMutationVariables>;

/**
 * __useCallRoutingUpdateRemarkMutation__
 *
 * To run a mutation, you first call `useCallRoutingUpdateRemarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCallRoutingUpdateRemarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [callRoutingUpdateRemarkMutation, { data, loading, error }] = useCallRoutingUpdateRemarkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCallRoutingUpdateRemarkMutation(baseOptions?: Apollo.MutationHookOptions<CallRoutingUpdateRemarkMutation, CallRoutingUpdateRemarkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CallRoutingUpdateRemarkMutation, CallRoutingUpdateRemarkMutationVariables>(CallRoutingUpdateRemarkDocument, options);
      }
export type CallRoutingUpdateRemarkMutationHookResult = ReturnType<typeof useCallRoutingUpdateRemarkMutation>;
export type CallRoutingUpdateRemarkMutationResult = Apollo.MutationResult<CallRoutingUpdateRemarkMutation>;
export type CallRoutingUpdateRemarkMutationOptions = Apollo.BaseMutationOptions<CallRoutingUpdateRemarkMutation, CallRoutingUpdateRemarkMutationVariables>;
export const CallAcceptDocument = gql`
    mutation CallAccept($input: CallAcceptInput!) {
  callAccept(input: $input) {
    id
    callSid
    date
    direction
    from
    status
    to
    userId
  }
}
    `;
export type CallAcceptMutationFn = Apollo.MutationFunction<CallAcceptMutation, CallAcceptMutationVariables>;

/**
 * __useCallAcceptMutation__
 *
 * To run a mutation, you first call `useCallAcceptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCallAcceptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [callAcceptMutation, { data, loading, error }] = useCallAcceptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCallAcceptMutation(baseOptions?: Apollo.MutationHookOptions<CallAcceptMutation, CallAcceptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CallAcceptMutation, CallAcceptMutationVariables>(CallAcceptDocument, options);
      }
export type CallAcceptMutationHookResult = ReturnType<typeof useCallAcceptMutation>;
export type CallAcceptMutationResult = Apollo.MutationResult<CallAcceptMutation>;
export type CallAcceptMutationOptions = Apollo.BaseMutationOptions<CallAcceptMutation, CallAcceptMutationVariables>;
export const CallCompleteDocument = gql`
    mutation CallComplete($input: CallCompleteInput!) {
  callComplete(input: $input) {
    id
    callSid
    date
    dateStarted
    dateEnded
    direction
    duration
    from
    status
    endedBy
    to
    userId
  }
}
    `;
export type CallCompleteMutationFn = Apollo.MutationFunction<CallCompleteMutation, CallCompleteMutationVariables>;

/**
 * __useCallCompleteMutation__
 *
 * To run a mutation, you first call `useCallCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCallCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [callCompleteMutation, { data, loading, error }] = useCallCompleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCallCompleteMutation(baseOptions?: Apollo.MutationHookOptions<CallCompleteMutation, CallCompleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CallCompleteMutation, CallCompleteMutationVariables>(CallCompleteDocument, options);
      }
export type CallCompleteMutationHookResult = ReturnType<typeof useCallCompleteMutation>;
export type CallCompleteMutationResult = Apollo.MutationResult<CallCompleteMutation>;
export type CallCompleteMutationOptions = Apollo.BaseMutationOptions<CallCompleteMutation, CallCompleteMutationVariables>;
export const CallCreateDocument = gql`
    mutation CallCreate($input: CallCreateInput!) {
  callCreate(input: $input) {
    id
    callSid
    date
    dateStarted
    dateEnded
    direction
    duration
    from
    status
    endedBy
    to
    userId
  }
}
    `;
export type CallCreateMutationFn = Apollo.MutationFunction<CallCreateMutation, CallCreateMutationVariables>;

/**
 * __useCallCreateMutation__
 *
 * To run a mutation, you first call `useCallCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCallCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [callCreateMutation, { data, loading, error }] = useCallCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCallCreateMutation(baseOptions?: Apollo.MutationHookOptions<CallCreateMutation, CallCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CallCreateMutation, CallCreateMutationVariables>(CallCreateDocument, options);
      }
export type CallCreateMutationHookResult = ReturnType<typeof useCallCreateMutation>;
export type CallCreateMutationResult = Apollo.MutationResult<CallCreateMutation>;
export type CallCreateMutationOptions = Apollo.BaseMutationOptions<CallCreateMutation, CallCreateMutationVariables>;
export const CallCancelDocument = gql`
    mutation CallCancel($input: CallRejectInput!) {
  callCancel(input: $input) {
    id
    callSid
    date
    dateStarted
    dateEnded
    direction
    duration
    from
    status
    endedBy
    to
    userId
  }
}
    `;
export type CallCancelMutationFn = Apollo.MutationFunction<CallCancelMutation, CallCancelMutationVariables>;

/**
 * __useCallCancelMutation__
 *
 * To run a mutation, you first call `useCallCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCallCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [callCancelMutation, { data, loading, error }] = useCallCancelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCallCancelMutation(baseOptions?: Apollo.MutationHookOptions<CallCancelMutation, CallCancelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CallCancelMutation, CallCancelMutationVariables>(CallCancelDocument, options);
      }
export type CallCancelMutationHookResult = ReturnType<typeof useCallCancelMutation>;
export type CallCancelMutationResult = Apollo.MutationResult<CallCancelMutation>;
export type CallCancelMutationOptions = Apollo.BaseMutationOptions<CallCancelMutation, CallCancelMutationVariables>;
export const CallRejectDocument = gql`
    mutation CallReject($input: CallRejectInput!) {
  callReject(input: $input) {
    id
    callSid
    date
    direction
    from
    routings {
      id
    }
    status
    to
    userId
  }
}
    `;
export type CallRejectMutationFn = Apollo.MutationFunction<CallRejectMutation, CallRejectMutationVariables>;

/**
 * __useCallRejectMutation__
 *
 * To run a mutation, you first call `useCallRejectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCallRejectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [callRejectMutation, { data, loading, error }] = useCallRejectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCallRejectMutation(baseOptions?: Apollo.MutationHookOptions<CallRejectMutation, CallRejectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CallRejectMutation, CallRejectMutationVariables>(CallRejectDocument, options);
      }
export type CallRejectMutationHookResult = ReturnType<typeof useCallRejectMutation>;
export type CallRejectMutationResult = Apollo.MutationResult<CallRejectMutation>;
export type CallRejectMutationOptions = Apollo.BaseMutationOptions<CallRejectMutation, CallRejectMutationVariables>;
export const CallUpdateSummaryDocument = gql`
    mutation CallUpdateSummary($input: CallUpdateSummaryInput!) {
  callUpdateSummary(input: $input) {
    id
    callSid
    date
    dateStarted
    dateEnded
    direction
    duration
    from
    summary
    status
    endedBy
    to
    userId
  }
}
    `;
export type CallUpdateSummaryMutationFn = Apollo.MutationFunction<CallUpdateSummaryMutation, CallUpdateSummaryMutationVariables>;

/**
 * __useCallUpdateSummaryMutation__
 *
 * To run a mutation, you first call `useCallUpdateSummaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCallUpdateSummaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [callUpdateSummaryMutation, { data, loading, error }] = useCallUpdateSummaryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCallUpdateSummaryMutation(baseOptions?: Apollo.MutationHookOptions<CallUpdateSummaryMutation, CallUpdateSummaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CallUpdateSummaryMutation, CallUpdateSummaryMutationVariables>(CallUpdateSummaryDocument, options);
      }
export type CallUpdateSummaryMutationHookResult = ReturnType<typeof useCallUpdateSummaryMutation>;
export type CallUpdateSummaryMutationResult = Apollo.MutationResult<CallUpdateSummaryMutation>;
export type CallUpdateSummaryMutationOptions = Apollo.BaseMutationOptions<CallUpdateSummaryMutation, CallUpdateSummaryMutationVariables>;
export const CallAssignDocument = gql`
    mutation CallAssign($input: CallAssignInput!) {
  callAssign(input: $input) {
    id
  }
}
    `;
export type CallAssignMutationFn = Apollo.MutationFunction<CallAssignMutation, CallAssignMutationVariables>;

/**
 * __useCallAssignMutation__
 *
 * To run a mutation, you first call `useCallAssignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCallAssignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [callAssignMutation, { data, loading, error }] = useCallAssignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCallAssignMutation(baseOptions?: Apollo.MutationHookOptions<CallAssignMutation, CallAssignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CallAssignMutation, CallAssignMutationVariables>(CallAssignDocument, options);
      }
export type CallAssignMutationHookResult = ReturnType<typeof useCallAssignMutation>;
export type CallAssignMutationResult = Apollo.MutationResult<CallAssignMutation>;
export type CallAssignMutationOptions = Apollo.BaseMutationOptions<CallAssignMutation, CallAssignMutationVariables>;
export const CallUnassignDocument = gql`
    mutation CallUnassign($input: CallUnassignInput!) {
  callUnassign(input: $input) {
    id
  }
}
    `;
export type CallUnassignMutationFn = Apollo.MutationFunction<CallUnassignMutation, CallUnassignMutationVariables>;

/**
 * __useCallUnassignMutation__
 *
 * To run a mutation, you first call `useCallUnassignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCallUnassignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [callUnassignMutation, { data, loading, error }] = useCallUnassignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCallUnassignMutation(baseOptions?: Apollo.MutationHookOptions<CallUnassignMutation, CallUnassignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CallUnassignMutation, CallUnassignMutationVariables>(CallUnassignDocument, options);
      }
export type CallUnassignMutationHookResult = ReturnType<typeof useCallUnassignMutation>;
export type CallUnassignMutationResult = Apollo.MutationResult<CallUnassignMutation>;
export type CallUnassignMutationOptions = Apollo.BaseMutationOptions<CallUnassignMutation, CallUnassignMutationVariables>;
export const CallMissedGroupAssignDocument = gql`
    mutation CallMissedGroupAssign($input: CallMissedGroupAssignInput!) {
  callMissedGroupAssign(input: $input) {
    id
  }
}
    `;
export type CallMissedGroupAssignMutationFn = Apollo.MutationFunction<CallMissedGroupAssignMutation, CallMissedGroupAssignMutationVariables>;

/**
 * __useCallMissedGroupAssignMutation__
 *
 * To run a mutation, you first call `useCallMissedGroupAssignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCallMissedGroupAssignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [callMissedGroupAssignMutation, { data, loading, error }] = useCallMissedGroupAssignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCallMissedGroupAssignMutation(baseOptions?: Apollo.MutationHookOptions<CallMissedGroupAssignMutation, CallMissedGroupAssignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CallMissedGroupAssignMutation, CallMissedGroupAssignMutationVariables>(CallMissedGroupAssignDocument, options);
      }
export type CallMissedGroupAssignMutationHookResult = ReturnType<typeof useCallMissedGroupAssignMutation>;
export type CallMissedGroupAssignMutationResult = Apollo.MutationResult<CallMissedGroupAssignMutation>;
export type CallMissedGroupAssignMutationOptions = Apollo.BaseMutationOptions<CallMissedGroupAssignMutation, CallMissedGroupAssignMutationVariables>;
export const CallMissedGroupUnassignDocument = gql`
    mutation CallMissedGroupUnassign($input: CallMissedGroupUnassignInput!) {
  callMissedGroupUnassign(input: $input) {
    id
  }
}
    `;
export type CallMissedGroupUnassignMutationFn = Apollo.MutationFunction<CallMissedGroupUnassignMutation, CallMissedGroupUnassignMutationVariables>;

/**
 * __useCallMissedGroupUnassignMutation__
 *
 * To run a mutation, you first call `useCallMissedGroupUnassignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCallMissedGroupUnassignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [callMissedGroupUnassignMutation, { data, loading, error }] = useCallMissedGroupUnassignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCallMissedGroupUnassignMutation(baseOptions?: Apollo.MutationHookOptions<CallMissedGroupUnassignMutation, CallMissedGroupUnassignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CallMissedGroupUnassignMutation, CallMissedGroupUnassignMutationVariables>(CallMissedGroupUnassignDocument, options);
      }
export type CallMissedGroupUnassignMutationHookResult = ReturnType<typeof useCallMissedGroupUnassignMutation>;
export type CallMissedGroupUnassignMutationResult = Apollo.MutationResult<CallMissedGroupUnassignMutation>;
export type CallMissedGroupUnassignMutationOptions = Apollo.BaseMutationOptions<CallMissedGroupUnassignMutation, CallMissedGroupUnassignMutationVariables>;
export const CallsUserActiveDocument = gql`
    query CallsUserActive($limit: Int, $offset: Int) {
  callsUserActive(limit: $limit, offset: $offset) {
    items {
      id
      callSid
      date
      dateEnded
      dateStarted
      direction
      duration
      from
      fromContact {
        id
        thirdPartyId
        displayName
        email
        phoneNumbers {
          rawForm
          canonicalForm
          countryCode
          number
          primary
          type
          label
        }
        issues {
          name
          message
        }
        googleUpdatedDate
        photoUrl
        lastSyncedDate
        createdDate
        updatedDate
      }
      status
      summary
      to
      toContact {
        id
        thirdPartyId
        displayName
        email
        phoneNumbers {
          rawForm
          canonicalForm
          countryCode
          number
          primary
          type
          label
        }
        issues {
          name
          message
        }
        googleUpdatedDate
        photoUrl
        lastSyncedDate
        createdDate
        updatedDate
      }
      userId
      user {
        id
        profile {
          fullName
        }
        thirdPartyId
      }
    }
    limit
    offset
    totalCount
  }
}
    `;

/**
 * __useCallsUserActiveQuery__
 *
 * To run a query within a React component, call `useCallsUserActiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallsUserActiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallsUserActiveQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useCallsUserActiveQuery(baseOptions?: Apollo.QueryHookOptions<CallsUserActiveQuery, CallsUserActiveQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CallsUserActiveQuery, CallsUserActiveQueryVariables>(CallsUserActiveDocument, options);
      }
export function useCallsUserActiveLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CallsUserActiveQuery, CallsUserActiveQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CallsUserActiveQuery, CallsUserActiveQueryVariables>(CallsUserActiveDocument, options);
        }
export function useCallsUserActiveSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CallsUserActiveQuery, CallsUserActiveQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CallsUserActiveQuery, CallsUserActiveQueryVariables>(CallsUserActiveDocument, options);
        }
export type CallsUserActiveQueryHookResult = ReturnType<typeof useCallsUserActiveQuery>;
export type CallsUserActiveLazyQueryHookResult = ReturnType<typeof useCallsUserActiveLazyQuery>;
export type CallsUserActiveSuspenseQueryHookResult = ReturnType<typeof useCallsUserActiveSuspenseQuery>;
export type CallsUserActiveQueryResult = Apollo.QueryResult<CallsUserActiveQuery, CallsUserActiveQueryVariables>;
export const CallsConcludedDocument = gql`
    query CallsConcluded($limit: Int, $offset: Int, $filter: ConcludedCallsFilterInput) {
  callsConcluded(limit: $limit, offset: $offset, filter: $filter) {
    items {
      id
      callSid
      date
      dateEnded
      dateStarted
      direction
      duration
      from
      fromContact {
        id
        thirdPartyId
        displayName
        email
        phoneNumbers {
          rawForm
          canonicalForm
          countryCode
          number
          primary
          type
          label
        }
        issues {
          name
          message
        }
        googleUpdatedDate
        photoUrl
        lastSyncedDate
        createdDate
        updatedDate
      }
      status
      summary
      to
      toContact {
        id
        thirdPartyId
        displayName
        email
        phoneNumbers {
          rawForm
          canonicalForm
          countryCode
          number
          primary
          type
          label
        }
        issues {
          name
          message
        }
        googleUpdatedDate
        photoUrl
        lastSyncedDate
        createdDate
        updatedDate
      }
      userId
      user {
        id
        profile {
          fullName
        }
        thirdPartyId
      }
    }
    limit
    offset
    totalCount
  }
}
    `;

/**
 * __useCallsConcludedQuery__
 *
 * To run a query within a React component, call `useCallsConcludedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallsConcludedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallsConcludedQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCallsConcludedQuery(baseOptions?: Apollo.QueryHookOptions<CallsConcludedQuery, CallsConcludedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CallsConcludedQuery, CallsConcludedQueryVariables>(CallsConcludedDocument, options);
      }
export function useCallsConcludedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CallsConcludedQuery, CallsConcludedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CallsConcludedQuery, CallsConcludedQueryVariables>(CallsConcludedDocument, options);
        }
export function useCallsConcludedSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CallsConcludedQuery, CallsConcludedQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CallsConcludedQuery, CallsConcludedQueryVariables>(CallsConcludedDocument, options);
        }
export type CallsConcludedQueryHookResult = ReturnType<typeof useCallsConcludedQuery>;
export type CallsConcludedLazyQueryHookResult = ReturnType<typeof useCallsConcludedLazyQuery>;
export type CallsConcludedSuspenseQueryHookResult = ReturnType<typeof useCallsConcludedSuspenseQuery>;
export type CallsConcludedQueryResult = Apollo.QueryResult<CallsConcludedQuery, CallsConcludedQueryVariables>;
export const CallsMissedDocument = gql`
    query CallsMissed($limit: Int, $offset: Int, $filter: MissedCallsFilterInput) {
  callsMissed(limit: $limit, offset: $offset, filter: $filter) {
    items {
      id
      callSid
      date
      dateEnded
      dateStarted
      direction
      duration
      from
      fromContact {
        id
        thirdPartyId
        displayName
        email
        phoneNumbers {
          rawForm
          canonicalForm
          countryCode
          number
          primary
          type
          label
        }
        issues {
          name
          message
        }
        googleUpdatedDate
        photoUrl
        lastSyncedDate
        createdDate
        updatedDate
      }
      status
      summary
      to
      toContact {
        id
        thirdPartyId
        displayName
        email
        phoneNumbers {
          rawForm
          canonicalForm
          countryCode
          number
          primary
          type
          label
        }
        issues {
          name
          message
        }
        googleUpdatedDate
        photoUrl
        lastSyncedDate
        createdDate
        updatedDate
      }
      userId
      user {
        id
        profile {
          fullName
        }
        thirdPartyId
      }
      missedCount
    }
    limit
    offset
    totalCount
  }
}
    `;

/**
 * __useCallsMissedQuery__
 *
 * To run a query within a React component, call `useCallsMissedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallsMissedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallsMissedQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCallsMissedQuery(baseOptions?: Apollo.QueryHookOptions<CallsMissedQuery, CallsMissedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CallsMissedQuery, CallsMissedQueryVariables>(CallsMissedDocument, options);
      }
export function useCallsMissedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CallsMissedQuery, CallsMissedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CallsMissedQuery, CallsMissedQueryVariables>(CallsMissedDocument, options);
        }
export function useCallsMissedSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CallsMissedQuery, CallsMissedQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CallsMissedQuery, CallsMissedQueryVariables>(CallsMissedDocument, options);
        }
export type CallsMissedQueryHookResult = ReturnType<typeof useCallsMissedQuery>;
export type CallsMissedLazyQueryHookResult = ReturnType<typeof useCallsMissedLazyQuery>;
export type CallsMissedSuspenseQueryHookResult = ReturnType<typeof useCallsMissedSuspenseQuery>;
export type CallsMissedQueryResult = Apollo.QueryResult<CallsMissedQuery, CallsMissedQueryVariables>;
export const CallDocument = gql`
    query Call($filter: CallFilterInput!) {
  call(filter: $filter) {
    id
    callSid
    date
    dateEnded
    dateStarted
    direction
    duration
    from
    fromContact {
      id
      thirdPartyId
      displayName
      email
      phoneNumbers {
        rawForm
        canonicalForm
        countryCode
        number
        primary
        type
        label
      }
      issues {
        name
        message
      }
      googleUpdatedDate
      photoUrl
      lastSyncedDate
      createdDate
      updatedDate
    }
    status
    summary
    to
    toContact {
      id
      thirdPartyId
      displayName
      email
      phoneNumbers {
        rawForm
        canonicalForm
        countryCode
        number
        primary
        type
        label
      }
      issues {
        name
        message
      }
      googleUpdatedDate
      photoUrl
      lastSyncedDate
      createdDate
      updatedDate
    }
    userId
    user {
      id
      profile {
        fullName
      }
      thirdPartyId
    }
    routings {
      id
      date
      remark
      status
      user {
        id
        profile {
          fullName
        }
        thirdPartyId
      }
    }
    recording {
      recordingSid
      recordingUrl
      synced
      thirdPartyUrl
      status
    }
  }
}
    `;

/**
 * __useCallQuery__
 *
 * To run a query within a React component, call `useCallQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCallQuery(baseOptions: Apollo.QueryHookOptions<CallQuery, CallQueryVariables> & ({ variables: CallQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CallQuery, CallQueryVariables>(CallDocument, options);
      }
export function useCallLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CallQuery, CallQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CallQuery, CallQueryVariables>(CallDocument, options);
        }
export function useCallSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CallQuery, CallQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CallQuery, CallQueryVariables>(CallDocument, options);
        }
export type CallQueryHookResult = ReturnType<typeof useCallQuery>;
export type CallLazyQueryHookResult = ReturnType<typeof useCallLazyQuery>;
export type CallSuspenseQueryHookResult = ReturnType<typeof useCallSuspenseQuery>;
export type CallQueryResult = Apollo.QueryResult<CallQuery, CallQueryVariables>;
export const PastInteractionsDocument = gql`
    query PastInteractions($clientPhoneNumber: String) {
  calls(
    filter: {from: $clientPhoneNumber, to: $clientPhoneNumber, statuses: [COMPLETED, CANCELED, MISSED, REJECTED], fromToCombinationMode: OR}
  ) {
    id
    callSid
    date
    dateStarted
    direction
    status
    user {
      id
      profile {
        fullName
      }
    }
    summary
  }
}
    `;

/**
 * __usePastInteractionsQuery__
 *
 * To run a query within a React component, call `usePastInteractionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePastInteractionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePastInteractionsQuery({
 *   variables: {
 *      clientPhoneNumber: // value for 'clientPhoneNumber'
 *   },
 * });
 */
export function usePastInteractionsQuery(baseOptions?: Apollo.QueryHookOptions<PastInteractionsQuery, PastInteractionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PastInteractionsQuery, PastInteractionsQueryVariables>(PastInteractionsDocument, options);
      }
export function usePastInteractionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PastInteractionsQuery, PastInteractionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PastInteractionsQuery, PastInteractionsQueryVariables>(PastInteractionsDocument, options);
        }
export function usePastInteractionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PastInteractionsQuery, PastInteractionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PastInteractionsQuery, PastInteractionsQueryVariables>(PastInteractionsDocument, options);
        }
export type PastInteractionsQueryHookResult = ReturnType<typeof usePastInteractionsQuery>;
export type PastInteractionsLazyQueryHookResult = ReturnType<typeof usePastInteractionsLazyQuery>;
export type PastInteractionsSuspenseQueryHookResult = ReturnType<typeof usePastInteractionsSuspenseQuery>;
export type PastInteractionsQueryResult = Apollo.QueryResult<PastInteractionsQuery, PastInteractionsQueryVariables>;
export const CallEventDocument = gql`
    subscription CallEvent($input: CallEventInput!) {
  callEvent(input: $input) {
    id
    callSid
    date
    dateEnded
    dateStarted
    direction
    duration
    from
    fromContact {
      id
      thirdPartyId
      displayName
      email
      phoneNumbers {
        rawForm
        canonicalForm
        countryCode
        number
        primary
        type
        label
      }
      issues {
        name
        message
      }
      googleUpdatedDate
      photoUrl
      lastSyncedDate
      createdDate
      updatedDate
    }
    status
    summary
    to
    toContact {
      id
      thirdPartyId
      displayName
      email
      phoneNumbers {
        rawForm
        canonicalForm
        countryCode
        number
        primary
        type
        label
      }
      issues {
        name
        message
      }
      googleUpdatedDate
      photoUrl
      lastSyncedDate
      createdDate
      updatedDate
    }
    userId
    user {
      id
      profile {
        fullName
      }
      thirdPartyId
    }
  }
}
    `;

/**
 * __useCallEventSubscription__
 *
 * To run a query within a React component, call `useCallEventSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCallEventSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallEventSubscription({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCallEventSubscription(baseOptions: Apollo.SubscriptionHookOptions<CallEventSubscription, CallEventSubscriptionVariables> & ({ variables: CallEventSubscriptionVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<CallEventSubscription, CallEventSubscriptionVariables>(CallEventDocument, options);
      }
export type CallEventSubscriptionHookResult = ReturnType<typeof useCallEventSubscription>;
export type CallEventSubscriptionResult = Apollo.SubscriptionResult<CallEventSubscription>;
export const ContactSyncDocument = gql`
    mutation ContactSync($input: ContactSyncInput!) {
  contactsSync(input: $input) {
    syncedCount
    nextSyncToken
  }
}
    `;
export type ContactSyncMutationFn = Apollo.MutationFunction<ContactSyncMutation, ContactSyncMutationVariables>;

/**
 * __useContactSyncMutation__
 *
 * To run a mutation, you first call `useContactSyncMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContactSyncMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contactSyncMutation, { data, loading, error }] = useContactSyncMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContactSyncMutation(baseOptions?: Apollo.MutationHookOptions<ContactSyncMutation, ContactSyncMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ContactSyncMutation, ContactSyncMutationVariables>(ContactSyncDocument, options);
      }
export type ContactSyncMutationHookResult = ReturnType<typeof useContactSyncMutation>;
export type ContactSyncMutationResult = Apollo.MutationResult<ContactSyncMutation>;
export type ContactSyncMutationOptions = Apollo.BaseMutationOptions<ContactSyncMutation, ContactSyncMutationVariables>;
export const ContactsDocument = gql`
    query Contacts($filter: ContactsFilterInput!, $limit: Int, $offset: Int) {
  contacts(filter: $filter, limit: $limit, offset: $offset) {
    items {
      id
      thirdPartyId
      displayName
      email
      phoneNumbers {
        rawForm
        canonicalForm
        countryCode
        number
        primary
        type
        label
      }
      issues {
        name
        message
      }
      googleUpdatedDate
      photoUrl
      lastSyncedDate
      createdDate
      updatedDate
    }
    totalCount
    limit
    offset
  }
}
    `;

/**
 * __useContactsQuery__
 *
 * To run a query within a React component, call `useContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useContactsQuery(baseOptions: Apollo.QueryHookOptions<ContactsQuery, ContactsQueryVariables> & ({ variables: ContactsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactsQuery, ContactsQueryVariables>(ContactsDocument, options);
      }
export function useContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactsQuery, ContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactsQuery, ContactsQueryVariables>(ContactsDocument, options);
        }
export function useContactsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ContactsQuery, ContactsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ContactsQuery, ContactsQueryVariables>(ContactsDocument, options);
        }
export type ContactsQueryHookResult = ReturnType<typeof useContactsQuery>;
export type ContactsLazyQueryHookResult = ReturnType<typeof useContactsLazyQuery>;
export type ContactsSuspenseQueryHookResult = ReturnType<typeof useContactsSuspenseQuery>;
export type ContactsQueryResult = Apollo.QueryResult<ContactsQuery, ContactsQueryVariables>;
export const SystemPreferenceUpdateDocument = gql`
    mutation SystemPreferenceUpdate($input: SystemPreferenceUpdateInput!) {
  systemPreferenceUpdate(input: $input) {
    id
    description
    key
    name
    value
  }
}
    `;
export type SystemPreferenceUpdateMutationFn = Apollo.MutationFunction<SystemPreferenceUpdateMutation, SystemPreferenceUpdateMutationVariables>;

/**
 * __useSystemPreferenceUpdateMutation__
 *
 * To run a mutation, you first call `useSystemPreferenceUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSystemPreferenceUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [systemPreferenceUpdateMutation, { data, loading, error }] = useSystemPreferenceUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSystemPreferenceUpdateMutation(baseOptions?: Apollo.MutationHookOptions<SystemPreferenceUpdateMutation, SystemPreferenceUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SystemPreferenceUpdateMutation, SystemPreferenceUpdateMutationVariables>(SystemPreferenceUpdateDocument, options);
      }
export type SystemPreferenceUpdateMutationHookResult = ReturnType<typeof useSystemPreferenceUpdateMutation>;
export type SystemPreferenceUpdateMutationResult = Apollo.MutationResult<SystemPreferenceUpdateMutation>;
export type SystemPreferenceUpdateMutationOptions = Apollo.BaseMutationOptions<SystemPreferenceUpdateMutation, SystemPreferenceUpdateMutationVariables>;
export const SystemPreferenceDocument = gql`
    query SystemPreference($filter: SystemPreferenceInput!) {
  systemPreference(filter: $filter) {
    id
    description
    key
    name
    value
  }
}
    `;

/**
 * __useSystemPreferenceQuery__
 *
 * To run a query within a React component, call `useSystemPreferenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemPreferenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemPreferenceQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSystemPreferenceQuery(baseOptions: Apollo.QueryHookOptions<SystemPreferenceQuery, SystemPreferenceQueryVariables> & ({ variables: SystemPreferenceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SystemPreferenceQuery, SystemPreferenceQueryVariables>(SystemPreferenceDocument, options);
      }
export function useSystemPreferenceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SystemPreferenceQuery, SystemPreferenceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SystemPreferenceQuery, SystemPreferenceQueryVariables>(SystemPreferenceDocument, options);
        }
export function useSystemPreferenceSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SystemPreferenceQuery, SystemPreferenceQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SystemPreferenceQuery, SystemPreferenceQueryVariables>(SystemPreferenceDocument, options);
        }
export type SystemPreferenceQueryHookResult = ReturnType<typeof useSystemPreferenceQuery>;
export type SystemPreferenceLazyQueryHookResult = ReturnType<typeof useSystemPreferenceLazyQuery>;
export type SystemPreferenceSuspenseQueryHookResult = ReturnType<typeof useSystemPreferenceSuspenseQuery>;
export type SystemPreferenceQueryResult = Apollo.QueryResult<SystemPreferenceQuery, SystemPreferenceQueryVariables>;
export const SystemPreferenceEventDocument = gql`
    subscription SystemPreferenceEvent {
  systemPreferenceEvent {
    id
    description
    key
    name
    value
  }
}
    `;

/**
 * __useSystemPreferenceEventSubscription__
 *
 * To run a query within a React component, call `useSystemPreferenceEventSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSystemPreferenceEventSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemPreferenceEventSubscription({
 *   variables: {
 *   },
 * });
 */
export function useSystemPreferenceEventSubscription(baseOptions?: Apollo.SubscriptionHookOptions<SystemPreferenceEventSubscription, SystemPreferenceEventSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SystemPreferenceEventSubscription, SystemPreferenceEventSubscriptionVariables>(SystemPreferenceEventDocument, options);
      }
export type SystemPreferenceEventSubscriptionHookResult = ReturnType<typeof useSystemPreferenceEventSubscription>;
export type SystemPreferenceEventSubscriptionResult = Apollo.SubscriptionResult<SystemPreferenceEventSubscription>;
export const UserUpdateAvailabilityDocument = gql`
    mutation UserUpdateAvailability($input: UserUpdateAvailabilityInput!) {
  userUpdateAvailability(input: $input) {
    availability {
      status
      updatedDate
    }
  }
}
    `;
export type UserUpdateAvailabilityMutationFn = Apollo.MutationFunction<UserUpdateAvailabilityMutation, UserUpdateAvailabilityMutationVariables>;

/**
 * __useUserUpdateAvailabilityMutation__
 *
 * To run a mutation, you first call `useUserUpdateAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateAvailabilityMutation, { data, loading, error }] = useUserUpdateAvailabilityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserUpdateAvailabilityMutation(baseOptions?: Apollo.MutationHookOptions<UserUpdateAvailabilityMutation, UserUpdateAvailabilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserUpdateAvailabilityMutation, UserUpdateAvailabilityMutationVariables>(UserUpdateAvailabilityDocument, options);
      }
export type UserUpdateAvailabilityMutationHookResult = ReturnType<typeof useUserUpdateAvailabilityMutation>;
export type UserUpdateAvailabilityMutationResult = Apollo.MutationResult<UserUpdateAvailabilityMutation>;
export type UserUpdateAvailabilityMutationOptions = Apollo.BaseMutationOptions<UserUpdateAvailabilityMutation, UserUpdateAvailabilityMutationVariables>;
export const UsersDocument = gql`
    query Users($filter: UsersFilterInput) {
  users(filter: $filter) {
    availability {
      status
      updatedDate
    }
    email
    id
    profile {
      fullName
    }
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export function useUsersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersSuspenseQueryHookResult = ReturnType<typeof useUsersSuspenseQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const UserEventDocument = gql`
    subscription UserEvent {
  userEvent {
    availability {
      status
      updatedDate
    }
    email
    id
    profile {
      fullName
    }
  }
}
    `;

/**
 * __useUserEventSubscription__
 *
 * To run a query within a React component, call `useUserEventSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUserEventSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserEventSubscription({
 *   variables: {
 *   },
 * });
 */
export function useUserEventSubscription(baseOptions?: Apollo.SubscriptionHookOptions<UserEventSubscription, UserEventSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UserEventSubscription, UserEventSubscriptionVariables>(UserEventDocument, options);
      }
export type UserEventSubscriptionHookResult = ReturnType<typeof useUserEventSubscription>;
export type UserEventSubscriptionResult = Apollo.SubscriptionResult<UserEventSubscription>;