"use client";

/** Third-party libraries */
import { UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Dropdown, Input, List, Spin, theme } from "antd";
import { FC, useMemo, useState } from "react";

/** Project components */
import { useCallAssignMutation, useCallMissedGroupAssignMutation, useCallMissedGroupUnassignMutation, useCallUnassignMutation } from "@/components/client/graphql";
import { StringUtility } from "@/components/common/utilities";
import { useAuthenticationContext } from "../authentication";
import { useUsersQuery } from "../graphql";
import { useDebouncedValue } from "../hooks/use-debounce";
import { CommunicationLog } from "./types";
const {
  useToken
} = theme;

// ===========================================================================
// ===========================================================================
// User with Avatar component
// ===========================================================================
// ===========================================================================

/**
 * Properties of the User with Avatar component.
 */
type UserWithAvatarProps = {
  /**
   * Use only the default user avatar.
   */
  defaultAvatar?: boolean;
  /**
   * Shows a loading indicator in place of the avatar.
   */
  loading?: boolean;
  /**
   * The name of the user. Defaults to `Unassigned`.
   */
  name?: string;
  /**
   * The size of the user chip.
   */
  size?: "small" | "large";
};

/**
 * Displays the avatar and name of a user.
 */
function UserWithAvatar({
  defaultAvatar,
  loading = false,
  name,
  size = "small"
}: UserWithAvatarProps) {
  const textSize = size === "small" ? "text-xs" : "text-sm";
  const avatarSize = size === "small" ? 20 : 24;
  const renderAvatar = function () {
    if (loading) {
      return <div className="min-w-5">
          <Spin className="text-tpl-red" size="small" />
        </div>;
    }
    if (name && !defaultAvatar) {
      return <Avatar size={avatarSize} className="!bg-neutral-grey">
          <div className={`font-semibold leading-5 !text-tpl-navy ${textSize}`}>
            {StringUtility.getInitials({
            input: name
          })}
          </div>
        </Avatar>;
    }
    return <Avatar icon={<UserOutlined className="!text-tpl-navy" />} size={avatarSize} className="!bg-neutral-grey" />;
  };
  return <div className={`flex h-6 w-full items-center text-left text-tpl-navy ${size === "small" ? "gap-1" : "gap-2"}`} data-sentry-component="UserWithAvatar" data-sentry-source-file="communication-log-user.tsx">
      <div className="min-w-4 flex-shrink-0">{renderAvatar()}</div>
      <div className={`line-clamp-1 flex-grow font-semibold ${textSize} break-words`}>
        {name || "Unassigned"}
        {/* The quick brown fox jumps over the lazy dogs over and over and over
         again. */}
      </div>
    </div>;
}

/** Types for the CommunicationLogAgentAssign component. */
type CommunicationLogUserProps = {
  /**
   * The communication log details.
   */
  communicationLog: Pick<CommunicationLog, "id" | "missedCount" | "user">;
  /** User/agent that the call is currently assigned to. */
  assignedUser?: {
    /** ID of the user/agent that the call is assigned to. */
    id: string;
    /** Name of the user/agent that the call is assigned to. */
    name: string;
  };
  /** Flag for enabling the dropdown menu. Defaults to `true`. */
  enabled?: boolean;
};

/**
 * Component for displaying the agent chip in the communication log card.
 * Also used for assigning/unassigning an agent to a call.
 */
export const CommunicationLogUser: FC<CommunicationLogUserProps> = props => {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  const {
    token
  } = useToken();

  /**
   * Logged on user details.
   */
  const {
    user
  } = useAuthenticationContext();

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  const [searchText, setSearchText] = useState("");
  const [debouncedSearchText, setDebouncedValue] = useDebouncedValue({
    value: searchText,
    ms: 500
  });
  const {
    data: usersData,
    loading: usersLoading
  } = useUsersQuery({
    /**
     * Do not update the GraphQL cache because this is just to display the list
     * of users for assigning the call.
     *
     * This could contain stale user availability status and might cause the
     * user availability status on the user list to be incorrect if allowed to
     * update the GraphQL cache.
     */
    fetchPolicy: "no-cache",
    variables: {
      filter: {
        name: debouncedSearchText
      }
    }
  });

  /**
   * List of users.
   */
  type UserAssignmentOption = {
    /**
     * The ID of the user.
     */
    id?: string;
    /**
     * This will default to `Unassigned` if the `id` is `undefined`.
     */
    name: string;
  } & Pick<UserWithAvatarProps, "defaultAvatar">;

  /**
   * List of users/agents that are not the logged on user.
   */
  const agentList = useMemo<UserAssignmentOption[]>(() => {
    if (!usersData?.users) return [];
    let _users = usersData.users ? [...usersData.users] : [];

    /**
     * Filter out the currently logged in user.
     */
    _users = _users.filter(_user => _user.id !== user?.id) || [];
    if (user?.id && usersData?.users.length) {
      /**
       * Currently logged on user details from the users query.
       */
      const currentUser = usersData?.users.find(_user => _user.id === user?.id);
      if (currentUser) {
        /**
         * Put the current user to the top of the list.
         */
        _users.unshift(currentUser);
      }
    }

    /**
     * Remove the currently assigned user.
     */
    if (props.communicationLog.user?.id) {
      _users = _users.filter(_user => _user.id !== props.communicationLog.user?.id);
    }
    const _userAssignmentOptions: UserAssignmentOption[] = _users.map(user => ({
      id: user.id,
      name: user.profile.fullName
    }));
    if (props.assignedUser?.id) {
      /**
       * Add an unassign option.
       */
      _userAssignmentOptions.unshift({
        id: undefined,
        defaultAvatar: true,
        name: "Unassign"
      });
    }
    return _userAssignmentOptions;
  }, [props.assignedUser?.id, props.communicationLog.user?.id, user, usersData?.users]);

  // ===========================================================================
  // ===========================================================================
  // Operations
  // ===========================================================================
  // ===========================================================================

  const [assignCallMutation, {
    loading: assignCallMutationLoading
  }] = useCallAssignMutation();
  const [assignCallMissedMutation, {
    loading: assignCallMissedMutationLoading
  }] = useCallMissedGroupAssignMutation();
  const [unassignCallMutation, {
    loading: unassignCallMutationLoading
  }] = useCallUnassignMutation();
  const [unassignCallMissedMutation, {
    loading: unassignCallMissedMutationLoading
  }] = useCallMissedGroupUnassignMutation();

  /**
   * Assigns a call differently depending whether:
   * - There are already more than 1 missed calls.
   * - There is only one missed call.
   */
  const onAssignCall = async (params: {
    callId: string;
    userId: string;
  }) => {
    // There are more than 1 most recent missed calls.
    if (props.communicationLog.missedCount) {
      await assignCallMissedMutation({
        variables: {
          input: {
            callId: params.callId,
            userId: params.userId
          }
        }
      });
    }
    // There is only 1 most recent missed call.
    else {
      await assignCallMutation({
        variables: {
          input: {
            callId: params.callId,
            userId: params.userId
          }
        }
      });
    }
  };

  /**
   * Unassigns a call differently depending whether:
   * - There are already more than 1 missed calls.
   * - There is only one missed call.
   */
  const onUnassignCall = async (params: {
    /**
     * ID of the call.
     */
    callId: string;
  }) => {
    // There are more than 1 most recent missed calls.
    if (props.communicationLog.missedCount) {
      await unassignCallMissedMutation({
        variables: {
          input: {
            callId: params.callId
          }
        }
      });
    }
    // There is only 1 most recent missed call.
    else {
      await unassignCallMutation({
        variables: {
          input: {
            callId: params.callId
          }
        }
      });
    }
  };

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  /**
   * Display a read only component when the dropdown is disabled.
   */
  if (!props.enabled) {
    return <UserWithAvatar name={props.assignedUser?.name} />;
  }
  return <Dropdown trigger={["click"]} placement="bottom" dropdownRender={menu => <div style={{
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    width: 300
  }} className="py-1">
          {/* Search bar */}
          <div className="px-4 py-2">
            <Input.Search allowClear onChange={e => setSearchText(e.target.value)} onClear={() => setDebouncedValue("")} onClick={e => e.stopPropagation()} onSearch={setSearchText} placeholder="Search" value={searchText} />
          </div>
          <div className="max-h-60 overflow-y-scroll">
            <List loading={usersLoading} dataSource={agentList} renderItem={item => <Button key={item.id} type="text" className={`w-full !rounded-none px-4 py-2 font-semibold text-tpl-navy hover:bg-sky-100 ${props.assignedUser?.id === item.id ? "!bg-sky-100" : ""}`} onClick={e => {
        e.stopPropagation();

        // Unassign.
        if (item.id === undefined) {
          onUnassignCall({
            callId: props.communicationLog.id
          });
        }
        // Assign to user.
        else {
          onAssignCall({
            callId: props.communicationLog.id,
            userId: item.id
          });
        }
      }}>
                  <UserWithAvatar defaultAvatar={item.defaultAvatar} name={item.name} size="large" />
                </Button>} />
          </div>
        </div>} data-sentry-element="Dropdown" data-sentry-component="CommunicationLogUser" data-sentry-source-file="communication-log-user.tsx">
      <Button type="link" size="small" className="!p-0" disabled={assignCallMutationLoading || unassignCallMutationLoading || assignCallMissedMutationLoading || unassignCallMissedMutationLoading} onClick={e => {
      e.preventDefault();
      e.stopPropagation();
    }} data-sentry-element="Button" data-sentry-source-file="communication-log-user.tsx">
        <UserWithAvatar loading={assignCallMutationLoading || unassignCallMutationLoading || assignCallMissedMutationLoading || unassignCallMissedMutationLoading} name={props.assignedUser?.name} data-sentry-element="UserWithAvatar" data-sentry-source-file="communication-log-user.tsx" />
      </Button>
    </Dropdown>;
};